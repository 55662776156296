import {
    Component
} from 'react'
import {

    Button,
    Form,
    Container,
    Grid,
    Header,
    Segment,
    Divider

} from 'semantic-ui-react'
import classes from './createApplication.module.css'
import HelpLabel from './helpLabel'
import constants from '../../../../constants'


const ExecutableInputs = (props) => {
    return (
        props.executables.map((val, idx) => {
            let ids = {}
            const fields = ["name", "displayName", "launchPath", "workingDirectory", "iconPath",
                "launchParameters", "addInCatalogue",
                "installationScript", "manifestFile", "installerFile"]
            for (const field of fields) {
                ids[`executable_${field}`] = `${field}-${idx}`
            }
            return (
                <Segment fluid padded inverted={constants.DARK_THEME}  raised>
                    <Form.Field >
                        <HelpLabel
                            help="Name of the Executable"
                            label="Executable Name"
                        />
                        <input
                            placeholder='Executable Name'
                            onChange={props.handleChange('executables')}
                            defaultValue={val.name}
                            data-id={idx}
                            id={ids[`executable_name`]}
                            data-field="name"
                        />
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="Display Name of the Executable"
                            label="Executable Display Name"
                        />
                        <input
                            placeholder='Display Name'
                            onChange={props.handleChange('executables')}
                            defaultValue={val.displayName}
                            data-id={idx}
                            id={ids[`executable_displayName`]}
                            data-field="displayName"
                        />
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="Absolute Path of the installed Windows Executable"
                            label="Launch Path"
                        />
                        <input
                            placeholder='Launch Path'
                            onChange={props.handleChange('executables')}
                            defaultValue={val.launchPath}
                            data-id={idx}
                            id={ids[`executable_launchPath`]}
                            data-field="launchPath"
                        />
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="Working Directory of the installed Windows Executable"
                            label="Working Directory"
                        />
                        <input
                            placeholder='Working Directory'
                            onChange={props.handleChange('executables')}
                            defaultValue={val.workingDirectory}
                            data-id={idx}
                            id={ids[`executable_workingDirectory`]}
                            data-field="workingDirectory"
                        />
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="Absolute Path of the installed Windows Executable Icon Path"
                            label="Icon Path"
                        />
                        <input
                            placeholder='Icon Path'
                            onChange={props.handleChange('executables')}
                            defaultValue={val.iconPath}
                            data-id={idx}
                            id={ids[`executable_iconPath`]}
                            data-field="iconPath"
                        />
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="Launch Parameters of the installed Windows Executable"
                            label="Launch Parameters"
                        />
                        <input
                            placeholder='Launch Parameters'
                            onChange={props.handleChange('executables')}
                            defaultValue={val.launchParameters}
                            data-id={idx}
                            id={ids[`executable_launchParameters`]}
                            data-field="launchParameters"
                        />
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="Powershell script which will be executed to launch Installer file"
                            label="Installation Script"
                        />
                        <input
                            type='file'
                            onChange={props.handleChange('executables')}
                            data-id={idx}
                            id={ids[`executable_installationScript`]}
                            data-field="installationScript"
                        />
                         <span>
                            {val.installationScript ? val.installationScript.name : ""}
                        </span>
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="List of absolute windows path which are required to start application"
                            label="Manifest File"
                        />
                        <input
                            type='file'
                            onChange={props.handleChange('executables')}
                            data-id={idx}
                            id={ids[`executable_manifestFile`]}
                            data-field="manifestFile"
                        />
                        <span>
                            {val.manifestFile ? val.manifestFile.name : ""}
                        </span>
                    </Form.Field>
                    <Form.Field >
                        <HelpLabel
                            help="Zip/Exe/Msi Windows executive file"
                            label="Installation File"
                        />
                        <input
                            type='file'
                            onChange={props.handleChange('executables')}
                            data-id={idx}
                            id={ids[`executable_installerFile`]}
                            data-field="installerFile"
                        />
                        <span>
                            {val.installerFile ? val.installerFile.name : ""}
                        </span>
                    </Form.Field>
                    {/* <Form.Group inline>
                        <HelpLabel
                            help="Marked Programs will be accessible to the user."
                            label="Add In Catalogue"
                        />
                        <Form.Radio
                            label='Yes'
                            value='yes'
                            checked={val.add_in_catalogue === 'yes'}
                            onChange={props.handleChange('executables')}
                            data-id={idx}
                            id={ids[`executable_add_in_catalogue`]+"_0"}
                            data-field="add_in_catalogue"
                        />
                        <Form.Radio
                            label='No'
                            value='no'
                            checked={val.add_in_catalogue === 'no'}
                            onChange={props.handleChange('executables')}
                            data-id={idx}
                            id={ids[`executable_add_in_catalogue`]+"_1"}
                            data-field="add_in_catalogue"
                        />
                       
                    </Form.Group> */}
                </Segment>
            )
        })
    )
}

class ApplicationExecutable extends Component {

    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    addExecutable = (e) => {
        e.preventDefault()
        this.props.handleChange('executables')(e)
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        const { values } = this.props;
        const { executables } = values;

        return (
            <Container fluid>
                <Grid inverted={constants.DARK_THEME}  textAlign="center" centered padded>
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h2' inverted={constants.DARK_THEME} textAlign="center">Executables</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column className={classes.stepFormContainer}>
                            <Form inverted={constants.DARK_THEME}  className={classes.stepForm}>
                                <Form.Field >
                                    <HelpLabel
                                        help="Powershell script which will be executed before initializing session"
                                        label="Pre Session Script"
                                    />
                                    <input
                                        type='file'
                                        onChange={this.props.handleChange('preSessionScript')}
                                        
                                    />
                                     <span>
                                        {values.preSessionScript ? values.preSessionScript.name : ""}
                                    </span>
                                </Form.Field>
                                <Form.Field >
                                    <HelpLabel
                                        help="Powershell script which will be executed after session ends"
                                        label="Post Session Script"
                                    />
                                    <input
                                        type='file'
                                        onChange={this.props.handleChange('postSessionScript')}
                                        
                                    />
                                     <span>
                                        {values.postSessionScript ? values.postSessionScript.name : ""}
                                    </span>
                                </Form.Field>
                                <ExecutableInputs executables={executables} handleChange={this.props.handleChange} />

                                <div className="ui two buttons">
                                    <Button onClick={this.addExecutable} data-type="add-executable" color="green">Add Executable </Button>
                                    <Button onClick={this.addExecutable} data-type="remove-executable" color="red">Remove Executable </Button>
                                </div>
                                <Divider />
                                <div className="ui two buttons">
                                    <Button onClick={this.back}>Back</Button>
                                    <Button onClick={this.saveAndContinue} primary>Save And Continue </Button>

                                </div>

                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }

}

export default ApplicationExecutable