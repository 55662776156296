import {
    Component
} from 'react'
import {

    Button,
   
    Modal,
    Form,
    Dropdown
} from 'semantic-ui-react'

class UserEditModal extends Component {
    state = { edit_modal_open: false, role: '', email: this.props.user.email , name: this.props.user.name}


    modal_open = () => this.setState({ edit_modal_open: true })
    modal_close = () => this.setState({ edit_modal_open: false })

    update_role = (role) => this.setState({ role: role })
    update_email = (email) => this.setState({email: email})
    update_name = (name) => this.setState({name: name})

    handleChange = input => event  => {
        if(event["type"] === "change"){
            if( input === "name"){
                this.update_name(event.target.value)
            }
            else if(input === "email"){
                this.update_email(event.target.value)
            }
        }
    }

    edit_user() {
        this.modal_close()
        if(this.props.profile){
            this.props.on_confirm(this.state.name)
        }
        else if(this.props.edit)
            this.props.on_confirm(this.props.user.username, this.state.role)
        else
            this.props.on_confirm(this.state.email, this.state.role) 
    }


    get_trigger = (edit, profile) => profile ? (edit? <span>Edit Profile</span> : <span>Add Profile</span>): (edit? <Button basic color='green'>Edit User</Button>: <Button color="blue">
    Add User
</Button>)


    render() {
        let { user } = this.props

        return (

            <Modal
                onClose={this.modal_close}
                onOpen={this.modal_open}
                open={this.state.edit_modal_open}
                size="mini"
                dimmer={'inverted'}
                trigger={ this.get_trigger(this.props.edit, this.props.profile) }
            >
                <Modal.Header>Edit User</Modal.Header>
                <Modal.Content >
                    <Form >
                        <Form.Field>
                            <label>Email</label>
                            <input placeholder='Email' disabled={this.props.edit} value={this.props.edit? user.email : this.state.email} 
                            onChange={this.handleChange("email")}/>
                        </Form.Field>
                        {
                            this.props.profile ? <Form.Field>
                            <label>Name</label>
                            <input placeholder='Name'  value={this.state.name}  onChange={this.handleChange("name")}/>
                        </Form.Field> :

                            
                                <Form.Field>
                                <label>Role</label>
                                <Dropdown
                                    selectOnBlur={false}
                                    fluid
                                    text={this.state.role}
                                    value={this.state.role}
                                    options={
                                        [{
                                            key: 'Select', value: 'ORGADMIN', text: 'ADMIN', disabled: false
                                        }, {
                                            key: 'Select', value: 'VIEWER', text: 'VIEWER', disabled: false
                                        }
                                        ]
                                    }
                                    onChange={(e, v) => {
                                        let value = v.value
                                        this.update_role(value)
                                    }}
                                />
                            </Form.Field>
                            
                        }
                        
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='black' onClick={this.modal_close}>
                        Cancel
                                </Button>
                    <Button color='blue'
                        onClick={this.edit_user.bind(this)}
                    >{this.props.edit ? "Edit" :  "Add"}</Button>
                </Modal.Actions>
            </Modal>

        )
    }
}

export default UserEditModal