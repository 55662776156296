
import * as utils from '../lib/utils'
import * as types from '../actions/types'
import createReducer from './createReducer'

let DEFAULT_STATE = {
  username: '',
  loggedIn: false,
  checkingLogin: false,
}

export const loginInfo = createReducer(utils.copyDict(DEFAULT_STATE), {
  [types.SET_LOGIN_INFO]: (state, action) => {
    state = { ...state }

    
    state.username = action.username
    state.user = action.user
    state.loggedIn = true

    let stateToSave = {
      username: state.username,
      loggedIn: state.loggedIn
    }
    utils.storageSet('loginInfo', stateToSave, function(error) {
      if (error) {
        console.log("err", error)
      }
    })
    return state
  },
  [types.SET_CHECKING_LOGIN]: (state, action) => {
    state = { ...state }

    state.checkingLogin = action.checkingLogin

    return state
  },
  [types.RESET]: (state, action) => {
    return utils.copyDict(DEFAULT_STATE)
  },
})
