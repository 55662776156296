import { setStatus } from './statusActions'
import constants from '../constants'
import history from '../lib/history'
import { ToastsStore } from 'react-toasts'

export function changeUrl(urlPrefix, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {

        // dispatch(setSyncFetching(true))
        dispatch(setStatus(constants.STATUS.URL_STATUS, constants.STATUS.SUCCESS))
        history.push(urlPrefix)
    }
}