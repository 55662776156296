import React from 'react';
import classes from './login.module.css'
import constants from '../../constants'
 

class CustomSignIn extends React.Component {
  render() {
    const LOGIN_TOKEN_URL= `${constants.AUTH_DOMAIN}/login?client_id=${constants.COGNITO.APP_CLIENT_ID}&response_type=code&scope=email+openid+aws.cognito.signin.user.admin`;
    return <div className={classes.host}>
          <div className={classes.sectionHeader}>
              <h3 className={classes.sectionTitle}>
                  Exestream
              </h3>
              <h3 className={classes.sectionSubTitle}>
                  MarketPlace for Desktop Applications
              </h3>
          </div>

          <div>
            <button className={classes.oauthButton} onClick={() => {
                                            window.location = `${LOGIN_TOKEN_URL}&redirect_uri=${window.location.origin}/dashboard`
                                        }}>
              <span className={classes.oauthContent}>Sign In/ Sign Up</span>
            </button>
          </div>
    </div>
     
  }
}

export default CustomSignIn