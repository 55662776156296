export const categories = [
    {
        "Id" :"business",
        "icon": "suitcase",
        "displayName" : "Business",
        "inverted" : false,
        "color": "yellow"
    },
    {
        "Id" :"developer_tools",
        "icon": "pencil alternate",
        "displayName" : "Developer Tools",
        "inverted" : false,
        "color": "orange"
    },
    {
        "Id" :"education",
        "icon": "book",
        "displayName" : "Education",
        "inverted" : false,
        "color": "green"
    },
    {
        "Id" :"entertainment",
        "icon": "tv",
        "displayName" : "Entertainment",
        "inverted" : false,
        "color": "yellow"
    },
    {
        "Id" :"finance",
        "icon": "suitcase",
        "displayName" : "Finance",
        "inverted" : false,
        "color": "yellow"
    },
    {
        "Id" :"games",
        "icon": "rocket",
        "displayName" : "Games",
        "inverted" : false,
        "color": "blue"
    },
    {
        "Id" :"graphics_design",
        "icon": "images",
        "displayName" : "Graphics & Design",
        "inverted" : false,
        "color": "blue"
    },
    {
        "Id" :"health_fitness",
        "icon": "bicycle",
        "displayName" : "Health & Fitness",
        "inverted" : false,
        "color": "blue"
    },
    {
        "Id" :"lifestyle",
        "icon": "bed",
        "displayName" : "LifeStyle",
        "inverted" : false,
        "color": "red"
    },
    {
        "Id" :"medical",
        "icon": "stethoscope",
        "displayName" : "Medical",
        "inverted" : false,
        "color": "pink"
    },
    {
        "Id" :"music",
        "icon": "music",
        "displayName" : "Music",
        "inverted" : false,
        "color": "blue"
    },
    {
        "Id" :"news",
        "icon": "newspaper",
        "displayName" : "News",
        "inverted" : false,
        "color": "grey"
    },{
        "Id" :"photo_video",
        "icon": "camera retro",
        "displayName" : "Photo & Video",
        "inverted" : false,
        "color": "grey"
    },
    {
        "Id" :"productivity",
        "icon": "paper plane",
        "displayName" : "Productivity",
        "inverted" : false,
        "color": "blue"
    },
    {
        "Id" :"reference",
        "icon": "search",
        "displayName" : "Reference",
        "inverted" : false,
        "color": "yellow"
    },
    {
        "Id" :"social_networking",
        "icon": "chat",
        "displayName" : "Social Networking",
        "inverted" : false,
        "color": "pink"
    },
    {
        "Id" :"sports",
        "icon": "football ball",
        "displayName" : "Sports",
        "inverted" : false,
        "color": "white"
    },
    {
        "Id" :"travel",
        "icon": "tree",
        "displayName" : "Travel",
        "inverted" : false,
        "color": "green"
    },
    {
        "Id" :"utilities",
        "icon": "calculator",
        "displayName" : "Utilities",
        "inverted" : false,
        "color": "grey"
    },
    {
        "Id" :"weather",
        "icon": "sun",
        "displayName" : "Weather",
        "inverted" : false,
        "color": "yellow"
    }
]

export const instanceSizes = [
    {
        "Id" : "STANDARD_SMALL",
        "displayName" : "Standard Small",
    },
    {
        "Id" : "STANDARD_MEDIUM",
        "displayName" : "Standard Medium",
    },
    {
        "Id" : "STANDARD_LARGE",
        "displayName" : "Standard Large",
    }
    
]