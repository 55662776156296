import appsyncHelper from '../lib/appsyncHelper'
import constants from '../constants'
import { ToastsStore } from 'react-toasts'
import * as types from './types'
import graphql_queries from '../graphql'
import { setStatus } from './statusActions'

export function fetchAllApplications(callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {

        dispatch(setStatus(constants.STATUS.ALL_APPLICATIONS_FETCH_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.applicationQuery.list_all_applications).then(res => {
            
            dispatch(setAllApplications(res['listApplications']['items']))
            dispatch(setStatus(constants.STATUS.ALL_APPLICATIONS_FETCH_STATUS, constants.STATUS.SUCCESS))
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            dispatch(setStatus(constants.STATUS.ALL_APPLICATIONS_FETCH_STATUS, constants.STATUS.FAILURE))
            callbackOnError(`${err}`)
        })
    }
}

export function fetchCategoryApplications (categoryId, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})){
    return (dispatch, getState) => {
        dispatch(setCategoryApplications([],categoryId))
        dispatch(setStatus(constants.STATUS.CATEGORY_APPLICATIONS_FETCH_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.applicationQuery.getApplicationsInCategory, {categoryId}).then(res => {

            dispatch(setCategoryApplications(res['listApplicationsInCategory']['items'], categoryId))
            dispatch(setStatus(constants.STATUS.CATEGORY_APPLICATIONS_FETCH_STATUS, constants.STATUS.SUCCESS))
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            dispatch(setStatus(constants.STATUS.CATEGORY_APPLICATIONS_FETCH_STATUS, constants.STATUS.FAILURE))
            callbackOnError("Error while fetching All applications")
        })
    }
}

export function fetchStreamingUrl(applicationID, username, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {

        // dispatch(setSyncFetching(true))
        dispatch(setStatus(constants.STATUS.STREAMING_URL_FETCH_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.applicationQuery.getApplication, { appId: applicationID, userId: username }).then(res => {
            
            dispatch(setStreamingUrl(applicationID, res['getApplication']['streamingUrl']))
            dispatch(setStatus(constants.STATUS.STREAMING_URL_FETCH_STATUS, constants.STATUS.SUCCESS))
            callbackOnSuccess()
            window.open(res['getApplication']['streamingUrl']["url"], "_blank")
            ToastsStore.info(`Url launched for the username ${res['getApplication']['streamingUrl']['username']}`)
        }).catch(err => {
            console.log("err", err)
            dispatch(setStatus(constants.STATUS.STREAMING_URL_FETCH_STATUS, constants.STATUS.FAILURE))
            callbackOnError("Error while fetching Streaming url")
        })
    }
}

export function searchApps(searchTerm, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {

        // dispatch(setSyncFetching(true))
        dispatch(setStatus(constants.STATUS.SEARCH_RESULT_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.applicationQuery.searchApps, {searchTerm}).then(res => {    

            dispatch(setSearchResults(res['listApplications']['items']))
            dispatch(setStatus(constants.STATUS.SEARCH_RESULT_STATUS, constants.STATUS.SUCCESS))
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            dispatch(setStatus(constants.STATUS.SEARCH_RESULT_STATUS, constants.STATUS.FAILURE))
            callbackOnError("Error while searching application")
        })
    }
}

export function getApp(applicationId, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {

        dispatch(setApplicationInfo({id: applicationId}))
        dispatch(setStatus(constants.STATUS.APPLICATION_DATA_FETCH_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.applicationQuery.getApplicationInfo, { appId: applicationId }).then(res => {    
            if(res['getApplication'])
                dispatch(setApplicationInfo(res['getApplication']))
            dispatch(setStatus(constants.STATUS.APPLICATION_DATA_FETCH_STATUS, constants.STATUS.SUCCESS))
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            dispatch(setStatus(constants.STATUS.APPLICATION_DATA_FETCH_STATUS, constants.STATUS.FAILURE))
            callbackOnError(`Error while fetching application ${applicationId}`)
        })
    }
}

export function updatePurchaseStatus(appId, purchaseStatus, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {
        ToastsStore.warning('Updating Application Purchase Status.')
        
        appsyncHelper.mutate(graphql_queries.applicationQuery.updatePaymentStatus, { appId, purchaseStatus }).then(res => {    
        ToastsStore.success('User Application purchased.')
        window.location.reload();
           
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            callbackOnError(`Error while updating payment status application ${appId}`)
        })
    }
}

export function fetchQuicksightDashboard( callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {
        
        dispatch(setStatus(constants.STATUS.GET_QUICKSIGHT_DASHBOARD_URL_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.applicationQuery.getDashboard).then(res => {    
            dispatch(setQuicksightUrl(res['getDashboard']['url']))
            dispatch(setStatus(constants.STATUS.GET_QUICKSIGHT_DASHBOARD_URL_STATUS, constants.STATUS.SUCCESS))
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            dispatch(setStatus(constants.STATUS.GET_QUICKSIGHT_DASHBOARD_URL_STATUS, constants.STATUS.FAILURE))
            callbackOnError(`Error while fetching quicksight dashboard`)
        })
    }
}



export function setAllApplications(appList) {
    return {
        type: types.SET_ALL_APPLICATIONS,
        appList
    }
}

export function setCategoryApplications(appList, category) {
    return {
        type: types.SET_CATEGORY_APPLICATIONS,
        appList,
        category
    }
}

export function setSearchResults(appList) {
    return {
        type: types.SET_SEARCH_RESULTS,
        appList
    }
}

export function setApplicationInfo(application) {
    return {
        type: types.SET_APP_INFO,
        application
    }
}

export function setStreamingUrl(applicationId, streamingUrl) {
    return {
        type: types.SET_STREAMING_URL,
        applicationId,
        streamingUrl
    }
}

export function setQuicksightUrl(embedUrl) {
    return {
        type: types.SET_QUICKSIGHT_URL,
        embedUrl
    }
}