import {
    Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    Dimmer,
    Loader,
    Image,
    Tab
} from 'semantic-ui-react'
import constants from '../../../../constants'
import { ActionCreators } from '../../../../actions'
import classes from './monitoring.module.css'
import _ from 'lodash'


class Monitoring extends Component {

    render() {
        let { statusInfo, quicksightInfo } = this.props
        let requestFetchStatus = _.get(
            statusInfo, `${constants.STATUS.GET_QUICKSIGHT_DASHBOARD_URL_STATUS}`, constants.STATUS.UNDEFINED
        )
        
        if (requestFetchStatus === constants.STATUS.UNDEFINED) {
            this.props.fetchQuicksightDashboard()
        }
        
        let quicksight_url = _.get(quicksightInfo, 'embedUrl', [])

        return (<div className={classes.root}>
            <Dimmer active={requestFetchStatus === constants.STATUS.IN_PROGRESS}>
                <Loader> Fetching Dashboard </Loader>
            </Dimmer>
            <span className={classes.header} >
               Monitoring
            </span>
            <div className={classes.content}>
                <iframe src={quicksight_url} className={classes.quicksightIframe}/>
            </div>
        </div>
        )

    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
    return {
        quicksightInfo: state.quicksightInfo,
        statusInfo: state.statusInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Monitoring)
