import appsyncHelper from '../lib/appsyncHelper'
import constants from '../constants'
import { ToastsStore } from 'react-toasts'
import * as types from './types'
import graphql_queries from '../graphql'
import { setStatus } from './statusActions'
import { ImWindows } from 'react-icons/im'



export function getAllUsers(callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {

        // dispatch(setSyncFetching(true))
        dispatch(setStatus(constants.STATUS.ALL_USERS_FETCH_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.userQuery.list_all_users).then(res => {
            dispatch(setAllUsers(res['listUsersOfOrganization']))
            dispatch(setStatus(constants.STATUS.ALL_USERS_FETCH_STATUS, constants.STATUS.SUCCESS))
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            dispatch(setStatus(constants.STATUS.ALL_USERS_FETCH_STATUS, constants.STATUS.FAILURE))
            callbackOnError("Error while fetching All users")
        })
    }
}

export function changeOrganizationRole(username, role, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {
        ToastsStore.warning('Updating User Membership.')
        
        appsyncHelper.mutate(graphql_queries.userQuery.updateUserRole, {username, role }).then(res => {
            ToastsStore.success(' User Membership updated.')   
            window.location.reload()
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            callbackOnError("Error while updating user membership")
        })
    }
}

export function removeOrganization(username,callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {
        ToastsStore.warning('Updating User Membership.')
        appsyncHelper.mutate(graphql_queries.userQuery.removeUserfromOrganization, {username}).then(res => {
            ToastsStore.success(' User Membership removed.')  
            window.location.reload()
            callbackOnSuccess()
        }).catch(err => {
            console.log("err", err)
            callbackOnError("Error while removing user membership")
        })
    }
}

export function getOrganization(callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
    console.log(err)
    ToastsStore.error(err)
})) {
    return (dispatch, getState) => {
        dispatch(setStatus(constants.STATUS.GET_USER_PROFILE_STATUS, constants.STATUS.IN_PROGRESS))
        appsyncHelper.query(graphql_queries.userQuery.getOrganization).then(res => {
            
            dispatch(setStatus(constants.STATUS.GET_USER_PROFILE_STATUS, constants.STATUS.SUCCESS))
            //callbackOnSuccess()
        }).catch(err => {
            dispatch(setStatus(constants.STATUS.GET_USER_PROFILE_STATUS, constants.STATUS.FAILURE))
            console.log("err", err)
            callbackOnError("Error while fetching organization of a user")
        })
    }
}

export function setAllUsers(userList) {
    return {
        type: types.SET_ALL_USERS,
        userList
    }
}

export function setUser(user) {
    return {
        type: types.SET_USER,
        user
    }
}