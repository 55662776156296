const list_all_applications = `
query MyQuery ($orgId: ID) {
    listApplications(orgId: $orgId) {
      items{
        executableId
        displayName
        id
        name
        brandLogo
        shortDescription
        purchaseStatus
      }    
    }
  }  
`

const getApplication = `
query MyQuery($appId: ID!) {
    getApplication(appId: $appId) {
      displayName
      executableId
      id
      name
      streamingUrl {
        url
        expires
        username
      }
    }
  }
`

const getApplicationInfo = `
query MyQuery($appId: ID!) {
    getApplication(appId: $appId) {
      displayName
      executableId
      id
      name
      brandLogo
      shortDescription
      description
      purchaseStatus
      
    }
  }
`


const getApplicationsInCategory = `
query MyQuery ($categoryId: AppCategory) {
  listApplicationsInCategory(category: $categoryId) {
    items{
      executableId
      displayName
      id
      name
      brandLogo
      shortDescription
      purchaseStatus
    }
  }
} 
`

const searchApps = `
query MyQuery ($orgId: ID, $searchTerm: String) {
  listApplications(orgId: $orgId, filter: {name: 
    {beginsWith: $searchTerm}}) {
    items{
      executableId
      displayName
      id
      name
      brandLogo
      shortDescription
      purchaseStatus
    }    
  }
}  
`


const updatePaymentStatus = `
mutation update ($appId: ID!, $purchaseStatus: PurchaseStatus){
  updatePaymentStatus(input : {
    id: $appId
    purchaseStatus: $purchaseStatus
  }){
    purchaseStatus
  }
}
`

const getDashboard = `
query MyQuery{
  getDashboard {
    url
  }
}
`

const createApplication = `
mutation create ($input: CreateApplicationInput!){
  createApplication(input: $input){
    id
    name
    brandLogo{
      url
      fields{
        key
        AWSAccessKeyId
        xamzsecuritytoken
        policy
        signature
      }
    }
    pageFavicon{
      url
      fields{
        key
        AWSAccessKeyId
        xamzsecuritytoken
        policy
        signature
      }
    }
    preSessionScript{
      url
      fields{
        key
        AWSAccessKeyId
        xamzsecuritytoken
        policy
        signature
      }
    }
    postSessionScript{
      url
      fields{
        key
        AWSAccessKeyId
        xamzsecuritytoken
        policy
        signature
      }
    }
    executables{
      id 
      name
      installationScript{
        url
        fields{
          key
          AWSAccessKeyId
          xamzsecuritytoken
          policy
          signature
        }
      }
      manifestFile{
        url
        fields{
          key
          AWSAccessKeyId
          xamzsecuritytoken
          policy
          signature
        }
      }
      installerFile{
        url
        fields{
          key
          AWSAccessKeyId
          xamzsecuritytoken
          policy
          signature
        }
      }
    }
  }
}
`

export const  applicationQuery = { getApplication, list_all_applications, getApplicationInfo, 
  createApplication, getApplicationsInCategory, updatePaymentStatus, searchApps, getDashboard}

