import {
    Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
   
    Step,
    Icon
} from 'semantic-ui-react'
import { ActionCreators } from '../../../../actions'
import classes from './createApplication.module.css'
import ApplicationDetail from './applicationDetail'
import ApplicationExecutable from './applicationExecutable'
import ApplicationDeploymentConfig from './applicationDeploymentConfig'
import ApplicationPreview from './applicationPreview'
import appsyncHelper from '../../../../lib/appsyncHelper'
import graphql_queries from '../../../../graphql'
import {uploadFileToS3} from '../../../../lib/utils'
import constants from '../../../../constants'


class CreateApplication extends Component {

    constructor(props) {
        super(props)
        this.state = {
            step: 1,
            name: '',
            displayName: '',
            shortDescription: '',
            description: '',
            brandLogo: '',
            websiteName: '',
            websiteLink: '',
            pageTitle: '',
            pageFavicon: '',
            orgName: '',
            executables: [],
            minFleetSize : 1,
            maxFleetSize: 5,
            instanceSize : 'STANDARD_SMALL',
            categories : [],
            preSessionScript: '',
            postSessionScript: '',
            logs: []
        }
        this.stepItems = [{
            stepindex: 1, title: "Application Info",
            description: "Fill your Application Branding Details",
            iconName: 'edit'
        }, {
            stepindex: 2, title: "Executables",
            description: "Upload Executables for the Application",
            iconName: 'upload'
        }, {
            stepindex: 3, title: "Application Info",
            description: "Update Application Deployment Configuration",
            iconName: 'cloud upload'
        }]
    }

    nextStep = () => {
        const { step } = this.state
        this.setState({
            step: step + 1
        })
    }

    prevStep = () => {
        const { step } = this.state
        this.setState({
            step: step - 1
        })
    }

    default_executable = () => {
        return {
            "name" : "",
            "displayName" : "",
            "launchPath": "",
            "installationScript": "",
            "manifestFile": "",
            "installerFile" : "",
            "workingDirectory" : "",
            "iconPath" : "",
            "launchParameters" : "",
            "addInCatalogue" : "no"
        }
    }

    handleChange = input => event => {
        
        if(event["type"] === "click"){
            if(event.target.dataset.type === "add-executable"){
                
                this.setState((prevState) => ({
                    executables: [...prevState.executables, this.default_executable()],
                  }));
            }else if(event.target.dataset.type === "remove-executable"){
                let executables = [...this.state.executables]
                executables.pop()
                this.setState({executables})
            }  
        }else if(event["type"] === "change"){
            if(input === "executables"){
                let executables = [...this.state.executables]
                if(event.target.files){
                    executables[event.target.dataset.id][event.target.dataset.field] = event.target.files[0]
                }else{
                    executables[event.target.dataset.id][event.target.dataset.field] = event.target.value
                }
                
                this.setState({executables})
            }
            else if(event.target.files){
                this.setState({ [input] : event.target.files[0] })
            }
            else{
                this.setState({ [input] : event.target.value })
            }
            
        }
        
    }

    submit = () => {
        console.log(this.state)
        let applicationInput = {
            name: this.state.name,
            brandLogo: this.state.brandLogo? this.state.brandLogo.name : '' ,
            displayName: this.state.displayName,
            shortDescription: this.state.shortDescription,
            description: this.state.description,
            websiteName: this.state.websiteName,
            websiteLink: this.state.websiteLink,
            pageTitle: this.state.pageTitle,
            pageFavicon: this.state.pageFavicon ? this.state.pageFavicon.name: '',
            orgName: this.state.orgName,
            executables: [],
            minFleetSize : this.state.minFleetSize,
            maxFleetSize: this.state.maxFleetSize,
            instanceSize : this.state.instanceSize,
            categories : this.state.categories,
            preSessionScript: this.state.preSessionScript?  this.state.preSessionScript.name : '',
            postSessionScript: this.state.postSessionScript? this.state.postSessionScript.name: '',
        }
        for (var executable of this.state.executables){
            var applicationExecutable = {
                name : executable.name,
                displayName : executable.displayName,
                launchPath: executable.launchPath,
                installationScript: executable.installationScript ? executable.installationScript.name: '',
                manifestFile: executable.manifestFile ? executable.manifestFile.name: '',
                installerFile : executable.installerFile ? executable.installerFile.name: '',
                workingDirectory : executable.workingDirectory,
                iconPath : executable.iconPath,
                launchParameters : executable.launchParameters,
                addInCatalogue : executable.addInCatalogue
            }
            applicationInput.executables.push(applicationExecutable)
        }
        this.setState({
            logs: []
        })
        appsyncHelper.mutate(graphql_queries.applicationQuery.createApplication, {input: applicationInput}).then(res => {   
            console.log("result;",  res);
            res = res.createApplication
            this.setState((prevState) => ({
                logs: [...prevState.logs, `Application  created successfully ${res.id}`],
              }));
            var uploadFields = ["brandLogo", "pageFavicon", "preSessionScript", "postSessionScript"]
            var uploadExecutableFields = ["manifestFile", "installerFile", "installationScript"]
            for (var field of uploadFields){
                if (res[field]){
                    uploadFileToS3(res[field], this.state[field]).then( result =>{
                        this.setState((prevState) => ({
                            logs: [...prevState.logs, `File ${result.name} uploaded Successfully`],
                          }));
                    }
                    ).catch(err => {
                        this.setState((prevState) => ({
                            logs: [...prevState.logs, `File ${err.name} upload Failed. ${err.message}`],
                          }));
                    })
                }
            }
            for (var i = 0; i< this.state.executables.length; i++){
                var executable  = this.state.executables[i]
                for (var field of uploadExecutableFields){
                    if (executable[field]){
                        uploadFileToS3(res.executables[i][field], executable[field]).then( result =>{
                            this.setState((prevState) => ({
                                logs: [...prevState.logs, `File ${result.name} uploaded Successfully`],
                              }));
                        }
                        ).catch(err => {
                            this.setState((prevState) => ({
                                logs: [...prevState.logs, `File ${err.name} upload Failed. ${err.message}`],
                              }));
                        })
                    }
                }
            }
        }).catch(err => {
            this.setState((prevState) => ({
                logs: [...prevState.logs, `Application failed to create ${err}`],
              }));
            console.log("err", err)
        })
    }

    renderForm(step, values){
        switch(step) {
            case 1:
                return <ApplicationDetail
                        nextStep={this.nextStep}
                        handleChange = {this.handleChange}
                        values={values}
                        />
            case 2:
                return <ApplicationExecutable
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange = {this.handleChange}
                        values={values}
                        />
            case 3:
                return <ApplicationDeploymentConfig
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        handleChange = {this.handleChange}
                        values={values}
                        />
            case 4:
                return <ApplicationPreview
                        nextStep={this.nextStep}
                        prevStep={this.prevStep}
                        submit = {this.submit}
                        values={values}
                        />
            default:
                return ""
            }
    }

    render() {
        const { step } = this.state;
        const {name, displayName, brandLogo, websiteName, websiteLink, pageTitle, shortDescription, description,
            pageFavicon, orgName, executables, minFleetSize, maxFleetSize, instanceSize , categories,
            preSessionScript, postSessionScript, logs
        } = this.state
        const values = { name, displayName, brandLogo, websiteName, websiteLink, pageTitle,shortDescription, description,
            pageFavicon, orgName , executables, minFleetSize, maxFleetSize, instanceSize, categories,
            preSessionScript, postSessionScript, logs
        };
        return (
            <div className={classes.root}>

                <span className={classes.header}>
                    Create Application
                </span>

                <div className={classes.content}>
                    <Step.Group fluid stackable='tablet' size='small'>
                        {
                            this.stepItems.map(stepItem => {
                                return (
                                    <Step key={stepItem.stepindex} active={step === stepItem.stepindex} disabled={step < stepItem.stepindex} completed={step > stepItem.stepindex}>
                                        <Icon inverted={constants.DARK_THEME} name={stepItem.iconName} />
                                        <Step.Content >
                                            <Step.Title>{stepItem.title}</Step.Title>
                                            <Step.Description>{stepItem.description}</Step.Description>
                                        </Step.Content>
                                    </Step>)

                            })
                        }
                    </Step.Group>
                    {
                        this.renderForm(step, values)
                    }
                </div>
            </div>

        )
    }

}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
    return {
        statusInfo: state.statusInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateApplication)
