import { Label, Popup } from 'semantic-ui-react'

const HelpLabel = ({ label, help }) => (
  <>
    <label style={{ display: 'inline', marginRight: '5px' }}>{label}</label>
    <Popup
      content={help}
      trigger={(
        <Label color="blue" circular style={{  marginBottom: '5px' }}>
          ?
        </Label>
      )}
    />
  </>
)
export default HelpLabel