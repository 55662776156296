import {
  Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
  Route,
  Switch,
  Redirect,
} from "react-router-dom"
import {
  Modal
} from 'semantic-ui-react'
import _ from 'lodash'
import { ActionCreators } from '../../actions'
import Header from './components/Header'
import Dashboard from './components/Dashboard'
import CategoryView from './components/Dashboard/categoryView'
import Leftbar from './components/Leftbar'
import Application from './components/Application'
import Monitoring from './components/Monitoring'
import classes from './App.module.css'
import Users from './components/Users'
import { Login } from '../Login'
import CreateApplication from './components/CreateApplication'

import constants from '../../constants'

class App extends Component {

  constructor(props) {
    super(props)
    this.state = {
      preparingDSJson: false,
      loading: false,
      loadingMsg: '',
      dashboardURLHash: '#',
    }
  }

  checkIfUserAdmin(user) {
    let groups = _.get(user, 'signInUserSession.accessToken.payload["cognito:groups"]', [])
    let isAdmin = false
    let isOrgAdmin = false
    let isViewer  = false
    for (let group of groups) {
      console.log("Group:", group)
      if (group == "Administrators") {
         isAdmin = true
      } else if (group.includes("ORGADMIN")) {
        isOrgAdmin = true
      }else if(group.includes("VIEWER")) {
        isViewer = true
      }
    }
    return {isAdmin, isOrgAdmin, isViewer}
  }



  render() {

    let {admin, isOrgAdmin, isViewer} = this.checkIfUserAdmin(this.props.user)
    console.log(admin, isOrgAdmin, isViewer)
    const noorganizaation = (!admin && !isOrgAdmin && !isViewer)
    const isAdmin = admin || isOrgAdmin
    return (
      <div className={classes.root}>


        <Switch>
          <Route exact path={constants.PAGES.LOGIN_PAGE}>
            <Login />
          </Route>
          <Route exact path="/">
            <Redirect to={constants.PAGES.LANDING_PAGE} />
          </Route>
          <Route path="*">
            <div className={classes.appBody}>
              <Header user={this.props.user} />
              <div className={classes.content}>
                {isAdmin && <Leftbar isAdmin={isAdmin} />}
                <Switch>
                  <Route exact path={constants.PAGES.LANDING_PAGE} >
                    <Dashboard isAdmin={isAdmin} noorganizaation={noorganizaation} />
                  </Route>
                  <Route exact path={constants.PAGES.CREATE_APPLICATION} >
                    <CreateApplication />
                  </Route>
                 
                  <Route path={constants.PAGES.CATEGORY_VIEW} render={(props) => <CategoryView isAdmin={isAdmin} match={props.match} />} >
                  </Route>
                  <Route path={constants.PAGES.APPLICATION_VIEW} render={(props) => <Application isAdmin={isAdmin} match={props.match} />}>
                  </Route>
                  <Route path={constants.PAGES.USERS}>
                    <Users />
                  </Route>
                  <Route path={constants.PAGES.MONITORING_PAGE}>
                    <Monitoring />
                  </Route>
                </Switch>
              </div>
            </div>
          </Route>
        </Switch>

      </div>


    )
  }

}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
