import {
    Component
} from 'react'
import _ from 'lodash'

import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import constants from '../../constants'
import { ActionCreators } from '../../actions'
import ModalBox from '../../components/ModalBox'
import FormView from '../../components/FormView'

import classes from './login.module.css'

class Login extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentData: {}
        }
    }

    render() {
        let { statusInfo } = this.props

        let loginStatus = _.get(
            statusInfo, constants.STATUS.LOGIN_STATUS, constants.STATUS.UNDEFINED
        )

        return (
            <ModalBox
                className={classes.loginModal}
                open={true}
                onClose={() => { }}
                header='Login'
                notScrolling
                hideCancelButton
                showHelpLink
                HelpLink='/signup'
                HelpText="Don't have an account"
                submitButtonLoading={loginStatus === constants.STATUS.IN_PROGRESS}
                submitLabel='Login'

                content={(
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                        borderRadius: 5,
                        maxHeight: window.innerHeight - 240,
                      }}>
                        <FormView
                            rowStyle={{ backgroundColor: 'transparent', marginBottom: 0 }}
                            hideAddButton
                            fields={[{
                                name: 'username', type: 'String'
                            }, {
                                name: 'password', type: 'Password'
                            }]}
                            currentData={this.state.currentData}
                            onChange={inputData => {
                                this.setState({ currentData: inputData })
                            }}
                        />
                    </div>
                )}
                onSubmit={() => {
                    if (loginStatus === constants.STATUS.IN_PROGRESS) {
                        return
                    }
                    this.props.login(this.state.currentData.username, this.state.currentData.password)
                }}
            />
        )
    }

}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
    return {
        loginInfo: state.loginInfo,
        objectInfo: state.objectInfo,
        statusInfo: state.statusInfo,
        departmentInfo: state.departmentInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login)
