import {
    Component
} from 'react'
import {

    Grid,
    Icon,
    Label

} from 'semantic-ui-react'
import history from '../../../../lib/history'
import classes from './dashboard.module.css'
import constants from '../../../../constants'
const Category = (props) => (
    <Grid.Column className={classes.categoryColumn} onClick={() => history.push(`/categories/${props.category.Id}`)} width={4}>
       
            <Icon
                floated='left'
                size='large'
                inverted={constants.DARK_THEME}
                name={props.category.icon}
                color={props.category.color}
            />
            <span className={classes.categoryHeader}>{props.category.displayName}</span>
        

    </Grid.Column>
)

const CategoryLabel = (props) => (
    <Label color={constants.DARK_THEME ? "black" : "white"}  onClick={() => history.push(`/categories/${props.category.Id}`)}  >
     <Icon
        name={props.category.icon}
        color={props.category.color}
    />
    {props.category.displayName}
    </Label>
    
)

class CategoryList extends Component {

    render() {
        let { categories } = this.props
        // return (
        //     <Grid>
        //         {categories.map(category => (
        //             <Category category={category} />
        //         ))}
        //     </Grid>
        // )
        return (
            <Label.Group size="large" className={classes.categoryList}>
                {categories.map(category => (
                    <CategoryLabel category={category} key={category.Id}/>
                ))}
            </Label.Group>
        )
    }
}

export default CategoryList