import {
    Component
  } from 'react'
  import { connect } from 'react-redux'
  import { bindActionCreators } from 'redux'
  import { MdList , MdFormatPaint, MdDashboard} from 'react-icons/md'
  import {ImUser} from 'react-icons/im'
  import constants from '../../../../constants'
  import history from '../../../../lib/history'
  import { ActionCreators } from '../../../../actions'
  import classes from './leftbar.module.css'

  
  class Leftbar extends Component {
  
    constructor(props) {
      super(props)
  
      this.state = {
      }
  
      this.barItems = [{
        urlPrefix: constants.PAGES.LIST_APPLICATIONS, caption: 'Discover',
        icon: <MdList size={20} className={classes.icon} />,
        admin: false
      },
       {
        urlPrefix: constants.PAGES.CREATE_APPLICATION, caption: 'Create',
        icon: <MdFormatPaint size={20} className={classes.icon} />,
        admin: true
      }, {
        urlPrefix: constants.PAGES.USERS, caption: 'Users',
        icon: <ImUser size={20} className={classes.icon} />,
        admin: true
      },
      {
        urlPrefix: constants.PAGES.MONITORING_PAGE, caption: 'Monitoring',
        icon: <MdDashboard size={20} className={classes.icon} />,
        admin: true
      }]
    }

    
    getPathPrefix() {
      let matchProp = this.props.matchProp
  
      let pathPrefixList = matchProp.path.split('/')
      let pathPrefix = 'home'
      if (pathPrefixList.length > 1) {
        pathPrefix = pathPrefixList[1]
      }
  
      return pathPrefix
    }
  
    render() {
        
        let selectedTab = history.location.pathname
        const {isAdmin} = this.props
      return (
        <div className={classes.root}>
           {
          this.barItems.map(barItem => 
            {
              if (isAdmin || !barItem.admin){
                return (<span key={barItem.urlPrefix} className={`${classes.item} ${
                  selectedTab === barItem.urlPrefix ? classes.selectedItem : ''
                }`} onClick={() => {
                  this.props.changeUrl(barItem.urlPrefix)
                }}> 
                  { barItem.icon }
                  <span className={classes.label}> { barItem.caption } </span> 
                </span>)
              }
              return ""
            }
            
          )
        }
        </div>
      )
    }
  }
  
  function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
  }
  
  function mapStateToProps(state) {
    return {
      statusInfo: state.statusInfo,
    }
  }
  
  export default connect(mapStateToProps, mapDispatchToProps)(Leftbar)
  