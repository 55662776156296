export const RESET = 'RESET'

export const SET_LOGIN_INFO = 'SET_LOGIN_INFO'
export const SET_CHECKING_LOGIN = 'SET_CHECKING_LOGIN'
export const SET_STATUS = 'SET_STATUS'

export const SET_ALL_APPLICATIONS = 'SET_ALL_APPLICATIONS'
export const SET_STREAMING_URL = 'SET_STREAMING_URL'

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_APP_INFO = 'SET_APP_INFO'

export const SET_ALL_USERS = 'SET_ALL_USERS'
export const DEFAULT = 'DEFAULT'

export const SET_USER = 'SET_USER'

export const SET_CATEGORY_APPLICATIONS = 'SET_CATEGORY_APPLICATIONS'

export const SET_QUICKSIGHT_URL = 'SET_QUICKSIGHT_URL'