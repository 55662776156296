import React from 'react'

export const copyDict = dict => {
  return JSON.parse(JSON.stringify(dict))
}

export const randomKey = (length=5) => {
  return Math.random().toString(36).slice(-length)
}

export const isDict = (v) => {
  return typeof v === 'object' && v !== null && !(v instanceof Array) && !(v instanceof Date);
}

export const storageSet = (key, data, errorCallback) => {
  localStorage.setItem(key, JSON.stringify(data))
}

export const storageGet = (key, callback) => {
  let data = localStorage.getItem(key)
  data = JSON.parse(data)

  callback(null, data)
}

export const storageRemove = (key, callback) => {
  localStorage.removeItem(key)
  callback()
}

export const convertArrayToObject = (dict, depth=0) => {
  if (depth > 1) {
    return dict
  }
  if (dict === null) {
    return dict
  }
  for (let key in dict) {
    dict[key] = typeof dict[key] === "object" ? {
      ...convertArrayToObject(dict[key], depth + 1)
    } : dict[key]
  }

  return dict
}

export const addKey = (components) => {
  return components.map((Component, index) => (
    <Component.Type {...Component.props} key={index} />
  ))
}

export const uuid4 = () => {
  // eslint-disable-next-line
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    // eslint-disable-next-line
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

export const toString = obj => {
  if (obj === null) {
    return 'null'
  }
  if (Array.isArray(obj)) {
    return `[${obj.map(val => toString(val)).join(', \n ')}]`.split('},').join(' },')
  }
  if (isJson(obj)) {
    let val = JSON.parse(obj)
    return JSON.stringify(val, null, 4)
  }
  if (typeof obj === 'string') {
    return obj
  }
  return JSON.stringify(obj, null, 4)
}

export const isJson = (str) => {
  try {
      JSON.parse(str);
  } catch (e) {
      return false;
  }
  return true;
}

export const capitalize = ([firstLetter, ...rest]) => {
  return [firstLetter.toLocaleUpperCase(), ...rest].join('');
}

export const trim = (s, c) => {
  if (c === "]") c = "\\]";
  if (c === "\\") c = "\\\\";
  return s.replace(new RegExp(
    "^[" + c + "]+|[" + c + "]+$", "g"
  ), "");
}

// https://stackoverflow.com/a/12300351/3947422
export const dataURItoBlob = (dataURI) => {
  var byteString = atob(dataURI.split(',')[1]);
  var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
  var ab = new ArrayBuffer(byteString.length);
  var ia = new Uint8Array(ab);
  for (var i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  var blob = new Blob([ab], {type: mimeString});
  return blob;
}

export const processPropertyKey = (key) => {
  let words = key.split('_')
  let capitalWords = []
  for (let word of words) {
    capitalWords.push(capitalize(word))
  }

  return capitalWords.join(' ')
}

export const values = dictObject => {
  return Object.keys(dictObject).map(key => dictObject[key])
}

export const shuffle = (array) => {
  var currentIndex = array.length, temporaryValue, randomIndex;

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {

    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;

    // And swap it with the current element.
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
}

export const hashCode = (str, hash = 5381) => {
  var i = str.length;

  while(i) {
    hash = (hash * 33) ^ str.charCodeAt(--i);
  }

  /* JavaScript does bitwise operations (like XOR, above) on 32-bit signed
  * integers. Since we want the results to be always positive, convert the
  * signed int to an unsigned by doing an unsigned bitshift. */
  return hash >>> 0;
};

export const shadeColor = (R, G, B, percent) => {
  R = parseInt(R * (100 + percent) / 100);
  G = parseInt(G * (100 + percent) / 100);
  B = parseInt(B * (100 + percent) / 100);

  R = (R<255)?R:255;
  G = (G<255)?G:255;
  B = (B<255)?B:255;

  var RR = ((R.toString(16).length===1)?"0"+R.toString(16):R.toString(16));
  var GG = ((G.toString(16).length===1)?"0"+G.toString(16):G.toString(16));
  var BB = ((B.toString(16).length===1)?"0"+B.toString(16):B.toString(16));

  return "#"+RR+GG+BB;
}

Object.values = Object.values || function(o){return Object.keys(o).map(function(k){return o[k]})};

export const convertTimestampToString = timestamp => {
  let date = new Date(timestamp)
  return date.toDateString() + ' ' + date.toLocaleTimeString()
}

export const getMessage = obj => {
  if (obj) {
    return obj.msg || obj.err
  }
  return ''
}

export const uploadFileToS3 = (presignedPostData, file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    presignedPostData.fields["x-amz-security-token"] = presignedPostData.fields["xamzsecuritytoken"]
    Object.keys(presignedPostData.fields).forEach(key => {
      if (key !== "xamzsecuritytoken" && key !== "__typename")
          formData.append(key, presignedPostData.fields[key]);
    });
    // Actual file has to be appended last.
    formData.append("file", file);
    const xhr = new XMLHttpRequest();
    xhr.open("POST", presignedPostData.url, true);
    xhr.send(formData);
    xhr.onload = function() {
      this.status === 204 ? resolve(
        {
          name: file.name
        }
      ) : reject({
        name: file.name,
        message: this.responseText
      });
    };
  });
};
