
import * as utils from '../lib/utils'
import * as types from '../actions/types'
import createReducer from './createReducer'

let DEFAULT_STATE = {
}

export const statusInfo = createReducer(utils.copyDict(DEFAULT_STATE), {
  [types.SET_STATUS]: (state, action) => {
    state = { ...state }

    state[action.statusName] = action.statusValue
    
    return state
  },
  [types.RESET]: (state, action) => {
    return utils.copyDict(DEFAULT_STATE)
  },
})