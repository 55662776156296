import {
    Component
} from 'react'

import {
   
    Card,
} from 'semantic-ui-react'
import ApplicationCard from './applicationCard'

class ListApplication extends Component {

   
    render() {
        let { streamin_url_status, fetchStreamingUrl, applications, updatePurchaseStatus } = this.props  
        return (
            <div>      
                <Card.Group className={this.props.className}>
                    {applications.map(application => (
                          <ApplicationCard application={application} 
                          fetchStreamingUrl={fetchStreamingUrl} 
                          updatePurchaseStatus = {updatePurchaseStatus}
                          load_status={streamin_url_status} key={application.Id}/> 
                    ))}
                </Card.Group>
            </div>
        )
    }

}

// function mapDispatchToProps(dispatch) {
//     return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
// }

// function mapStateToProps(state) {
//     return {
//         statusInfo: state.statusInfo
//     }
// }

// export default connect(mapStateToProps, mapDispatchToProps)(ListApplication)

export default ListApplication