import * as utils from '../lib/utils'
import * as types from '../actions/types'
import createReducer from './createReducer'

let DEFAULT_STATE = {
  'userList': [],
  
}

export const usersInfo = createReducer(utils.copyDict(DEFAULT_STATE), {
    [types.SET_ALL_USERS] : (state, action) =>{
      state = { ...state }
      state.userList = action.userList
      return state
    },
    [types.SET_USER] : (state, action) =>{
      state = { ...state }
      state.user = action.user
      return state
    },
  })
  
