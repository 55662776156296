import {
    Component
} from 'react'
import {

    Button,
    Card,
    Image,
    Label

} from 'semantic-ui-react'
import history from '../../../../lib/history'
import constants from '../../../../constants'

const PURCHASE_STATUS = {
    "NOT_PURCHASED": 1,
    "ON_TRIAL": 2,
    "TRIAL_EXPIRED": 3,
    "PURCHASED": 4
}


class ApplicationCard extends Component {

    LaunchButton = () => (
        <Button primary disabled={this.props.application.purchaseStatus == "TRIAL_EXPIRED"} onClick={() => this.props.fetchStreamingUrl(this.props.application.id)}
            loading={this.props.streaming_url_load_status === constants.STATUS.IN_PROGRESS}>
            Launch
        </Button  >)

    PurchaseButton = () => (
        <Button positive onClick={() => this.props.updatePurchaseStatus(this.props.application.id, "PURCHASED")} >
            Buy
        </Button  >)

    StartTrialButton = () => (
        <Button primary onClick={() => this.props.updatePurchaseStatus(this.props.application.id, "ON_TRIAL")}>
            Start Trial
        </Button  >
    )


    render() {
        let { application } = this.props
        let purchase_status_step = PURCHASE_STATUS[application.purchaseStatus]
        return (
            <Card raised  >

                <Card.Content onClick={() => history.push(`/application/${application.id}`)} >
                    <Image
                        floated='left'
                        size='tiny'
                        src={application.brandLogo}
                    />
                    <Card.Header >{application.displayName}</Card.Header>
                    <Card.Meta >{application.shortDescription}</Card.Meta>
                </Card.Content>
                <Card.Content extra>
                    {
                        purchase_status_step == 2 && <Label pointing="below">On Trial</Label>
                    }
                    {
                        purchase_status_step == 3 && <Label pointing="below">Trial Expired.</Label>
                    }
                    <div className="ui two buttons ">
                        {
                            purchase_status_step <2 && <this.StartTrialButton/>                 
                        }
                        {
                            (purchase_status_step >1 ) && <this.LaunchButton/>                 
                        }
                        {
                            purchase_status_step <4 && <this.PurchaseButton/>                 
                        }
                    </div>

                </Card.Content>
            </Card>

        )
    }
}

export default ApplicationCard