import {
    Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    Dimmer,
    Loader,
    Table,
    Button,
    Card,
    Search
} from 'semantic-ui-react'
import { MdEdit, MdDeleteForever } from 'react-icons/md'
import constants from '../../../../constants'
import { ActionCreators } from '../../../../actions'
import classes from './users.module.css'
import _ from 'lodash'
import UserCard from './usercard'
import UserEditModal from './usereditModal'

const UserComponent = (props) => (
    <Table.Row>
        <Table.Cell>
            {props.user.name}
        </Table.Cell>
        <Table.Cell >{props.user.email}</Table.Cell>
        <Table.Cell>
            {props.user.type}
        </Table.Cell>
        <Table.Cell >
            {props.user.status}
        </Table.Cell>
        <Table.Cell textAlign="right">
            <div ui buttons two>
                <Button size="mini" inverted={constants.DARK_THEME}>
                    <MdEdit />
                </Button>
                <Button size="mini" inverted={constants.DARK_THEME}>
                    <MdDeleteForever />
                </Button>
            </div>
        </Table.Cell>
    </Table.Row>
);



class Users extends Component {

    constructor(props) {
        super(props)
        this.state = {searchUserValue : '', filteredUsers: [] , filtered: false}
    }

    handleSearchChange = (e, { value }) => {
        this.setState({searchUserValue: value })
        let users = _.get(this.props.usersInfo, 'userList', [])
        let filtered_users =  users.filter(user =>
            Object.keys(user).some(
              key =>user[key] ?
                user[key]
                  .toLowerCase()
                  .indexOf(value.toLowerCase()) !== -1 : false,
            ),
          );    
        this.setState({filteredUsers: filtered_users, filtered : true })
      }

    


    render() {
        let { statusInfo, usersInfo } = this.props
        let requestFetchStatus = _.get(
            statusInfo, `${constants.STATUS.ALL_USERS_FETCH_STATUS}`, constants.STATUS.UNDEFINED
        )
        if (requestFetchStatus === constants.STATUS.UNDEFINED) {
            this.props.getAllUsers()
        }
        let users = _.get(usersInfo, 'userList', [])
        if (this.state.filtered)
            users = this.state.filteredUsers
        console.log("Users", users)
        if(!users)
            users =[]
        return (
            <div className={classes.root}>
                <Dimmer active={requestFetchStatus === constants.STATUS.IN_PROGRESS}>
                    <Loader> Fetching Users </Loader>
                </Dimmer>
                <span className={classes.header}>
                    Users
                </span>

                <div className={classes.content}>

                    {/* <Table padded inverted={constants.DARK_THEME} selectable stackable >

                        <Table.Header fullWidth>
                            <Table.Row>
                                <Table.HeaderCell>Name</Table.HeaderCell>
                                <Table.HeaderCell>Email</Table.HeaderCell>
                                <Table.HeaderCell>Role</Table.HeaderCell>
                                <Table.HeaderCell>Status</Table.HeaderCell>
                                <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {users.map(user => (
                                <UserComponent user={user} />
                            ))}
                        </Table.Body>
                    </Table> */}
                    <div className="ui row">
                        <UserEditModal on_confirm={this.props.changeOrganizationRole} edit={false} user={{}}/>
                        <Search
                            open =  {false}
                            placeholder="Search User ..."
                            value = {this.state.searchUserValue}
                            onSearchChange={_.debounce(this.handleSearchChange, 500, {
                                leading: true,
                              })}
                        />
                    </div>
                    <Card.Group>
                    {users.map(user => (
                                <UserCard key={user.user_id} user={user} editUser={this.props.changeOrganizationRole} removeUser={this.props.removeOrganization} />
                            ))}
                    </Card.Group>

                </div>
            </div>

        )

    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
    return {
        usersInfo: state.usersInfo,
        statusInfo: state.statusInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)
