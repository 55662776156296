const list_all_users = `
query MyQuery ($orgId: ID){
  listUsersOfOrganization (orgId: $orgId){
      email
      name
      status
      type
      user_id
      username
    }
  }`

  const getOrganization = `
  query MyQuery {
    getOrganizationOfUser {
      name
      displayName
    }
  }
  `

  const updateUserRole = `
  mutation add($username: String!, $role: UserRole){
    updateUserRole(username: $username, role: {
      role : $role
    }){
      name
      email
      status
      type
    }
  }
  `

  const removeUserfromOrganization = `
  mutation add ($username: String!){
    removeUserFromOrganization(username: $username){
      name
      email
      status
      type
    }
  }
  `

  export const  userQuery = { list_all_users,getOrganization, updateUserRole ,removeUserfromOrganization}
