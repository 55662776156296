import {
    Component
} from 'react'
import {

    Button,
    Card,
    Label,
    Confirm,
   
} from 'semantic-ui-react'
import UserEditModal from './usereditModal'
import constants from '../../../../constants'

class UserCard extends Component {
    state = { delete_confirm_open: false, edit_modal_open: false, role: '' }

    confirm_open = () => this.setState({ delete_confirm_open: true })
    confirm_close = () => this.setState({ delete_confirm_open: false })
    

    update_role = (role) => this.setState({ role: role })

    delete_user() {
        this.confirm_close()
        this.props.removeUser(this.props.user.username)
    }

    render() {
        let { user } = this.props
        let header = user.name
        if (!header)
            header = user.email.split("@")[0].replace(".", " ").replace(
                /\w\S*/g,
                function(txt) {
                  return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
                }
              )
        return (
            <Card >
                <Card.Content>
                    <Card.Header>{header}</Card.Header>
                    <Card.Meta>{user.email}</Card.Meta>
                    <Card.Description>
                        <Label color={constants.DARK_THEME ? "black" : "white"}>{user.type}</Label>
                        {/* <Label color='black'>{user.status}</Label> */}
                    </Card.Description>
                </Card.Content>
                <Card.Content extra>
                    <div className='ui two buttons'>
                       
                        <UserEditModal on_confirm={this.props.editUser} edit={true} user={user}/>
                        <Button basic color='red' onClick={this.confirm_open}>
                            Delete User
                        </Button>
                        <Confirm
                            open={this.state.delete_confirm_open}
                            content={`The user ${user.email} will be removed from your organization. Are you sure? `}
                            onCancel={this.confirm_close}
                            onConfirm={this.delete_user.bind(this)}
                        />
                    </div>
                </Card.Content>
            </Card>
        )
    }
}

export default UserCard