import {
    Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import {
    Dimmer,
    Loader,
    Image,
    Tab
} from 'semantic-ui-react'
import constants from '../../../../constants'
import { ActionCreators } from '../../../../actions'
import classes from './application.module.css'
import _ from 'lodash'

class Application extends Component {

    render() {
        const params = _.get(this.props, "match.params", {})
        let { id } = params;
        let { statusInfo, appInfo } = this.props
        let requestFetchStatus = _.get(
            statusInfo, `${constants.STATUS.APPLICATION_DATA_FETCH_STATUS}`, constants.STATUS.UNDEFINED
        )
        let appId = _.get(appInfo, 'app.id', "")
        if ((requestFetchStatus === constants.STATUS.UNDEFINED) || (requestFetchStatus === constants.STATUS.SUCCESS && appId !== id)) {
            this.props.getApp(id)
        }
        let app = appInfo.app
        if(!app){
            return <div className={classes.root}>
                <span className={classes.header} style={ this.props.isAdmin ? {}: {
                position: "relative",
                left: "var(--leftbar-width)"
            }}>
                    Application Not Found
                </span>
            </div>
        }
        let tab_panes = [
            {
                menuItem: { key: 'users',  content: 'Description' },
                render: () => <Tab.Pane><p style={{fontSize: "18px"}}>{app.description}</p></Tab.Pane>,
            }
        ] 
        return (
            <div className={classes.root}>
                <Dimmer active={requestFetchStatus === constants.STATUS.IN_PROGRESS}>
                    <Loader> Fetching Application </Loader>
                </Dimmer>
                <span className={classes.header} style={ this.props.isAdmin ? {}: {
                position: "relative",
                left: "var(--leftbar-width)"
            }}>
                <Image
                    floated='left'
                    size='tiny'
                    src={app.brandLogo}
                />
                <div className={classes.column}>
                    {app.displayName}
                    <span className={classes.description}>
                        {app.shortDescription}
                    </span>
                </div>
                </span>
                
                <div className={classes.content}>
                        <Tab  panes={tab_panes} />
                </div>
            </div>

        )

    }
}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
    return {
        appInfo: state.appInfo,
        statusInfo: state.statusInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Application)
