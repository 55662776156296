import {
    Component
} from 'react'
import {

    Button,
    Form,
    Header,
    Grid,
    Container,
    TextArea

} from 'semantic-ui-react'
import classes from './createApplication.module.css'
import HelpLabel from './helpLabel'
import { categories } from '../../../../lib/appData'
import constants from '../../../../constants'

class ApplicationDetail extends Component {

    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.nextStep()
    }

    render() {
        const { values } = this.props;
        let category_options = []
        for (let category of categories) {
            category_options.push({
                key: category.Id,
                text: category.displayName,
                value: category.Id
            })
        }
        return (
            <Container fluid>
                <Grid inverted={constants.DARK_THEME} textAlign="center" centered padded >
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h2' inverted={constants.DARK_THEME}  textAlign="center">Application Details</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column className={classes.stepFormContainer}>
                            <Form inverted={constants.DARK_THEME}  className={classes.stepForm}>

                                <Form.Field >
                                    <HelpLabel
                                        help="Name of the Application"
                                        label="Application Name"
                                    />
                                    <input
                                        placeholder='Application Name'
                                        onChange={this.props.handleChange('name')}
                                        defaultValue={values.name}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <label>Display Name</label>
                                    <input
                                        placeholder='Display Name'
                                        onChange={this.props.handleChange('displayName')}
                                        defaultValue={values.displayName}
                                    />
                                </Form.Field>
                                <Form.Field >
                                <HelpLabel
                                        help="One line description of the application"
                                        label="Short Description"
                                    />
                                    <input
                                        placeholder='Short Description'
                                        onChange={this.props.handleChange('shortDescription')}
                                        defaultValue={values.shortDescription}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <label>Description</label>
                                    <TextArea
                                        placeholder='Description'
                                        onChange={this.props.handleChange('description')}
                                        defaultValue={values.description}
                                    />
                                </Form.Field>
                                <Form.Field>
                                    <HelpLabel
                                        help="PNG/JPEG logo image"
                                        label="Brand Logo"
                                    />
                                    <input

                                        type='file'
                                        onChange={this.props.handleChange('brandLogo')}
                                    />
                                    <span>
                                        {values.brandLogo ? values.brandLogo.name : ""}
                                    </span>
                                </Form.Field>
                                <Form.Field >
                                    <label>Website Name</label>
                                    <input
                                        placeholder='Website Name'
                                        onChange={this.props.handleChange('websiteName')}
                                        defaultValue={values.websiteName}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <label>Website Link</label>
                                    <input
                                        placeholder='Website Link'
                                        onChange={this.props.handleChange('websiteLink')}
                                        defaultValue={values.websiteLink}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <label>Page Title</label>
                                    <input
                                        placeholder='Page Title'
                                        onChange={this.props.handleChange('pageTitle')}
                                        defaultValue={values.pageTitle}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <HelpLabel
                                        help="PNG/JPEG favicon image"
                                        label="Page Favicon"
                                    />
                                    <input
                                        type='file'
                                        onChange={this.props.handleChange('pageFavicon')}
                                    />
                                    <span>
                                        {values.pageFavicon ? values.pageFavicon.name : ""}
                                    </span>
                                </Form.Field>
                                <Form.Field >
                                    <label>Organization Name</label>
                                    <input
                                        placeholder='Organization Name'
                                        onChange={this.props.handleChange('orgName')}
                                        defaultValue={values.orgName}
                                    />
                                </Form.Field>
                                <Form.Dropdown name="categories" placeholder='Select Categories...'
                                    defaultValue={values.categories}
                                    onChange={(e,{value}) => {
                                            e.type = "change"
                                            e.target.value = value
                                            this.props.handleChange('categories')(e)
                                        }
                                    }
                                    fluid multiple selection options={category_options} />


                                <Button onClick={this.saveAndContinue} primary>Save And Continue </Button>

                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>

        )
    }

}

export default ApplicationDetail