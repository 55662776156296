import {
    Component
} from 'react'
import {

    Button,
    Form,
    Container,
    Grid,
    Header

} from 'semantic-ui-react'
import classes from './createApplication.module.css'
import HelpLabel from './helpLabel'
import constants from '../../../../constants'
import { instanceSizes } from '../../../../lib/appData'

class ApplicationDeploymentConfig extends Component {

    saveAndContinue = (e) => {
        e.preventDefault()

        this.props.nextStep()
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }


    render() {
        const { values } = this.props;
        let instance_size_options = []
        for (let instance of instanceSizes) {
            instance_size_options.push({
                key: instance.Id,
                text: instance.displayName,
                value: instance.Id
            })
        }
        return (
            <Container fluid>
                <Grid inverted={constants.DARK_THEME} textAlign="center" centered padded >
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h2' inverted={constants.DARK_THEME} textAlign="center">Deployment Config</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column className={classes.stepFormContainer}>
                            <Form inverted={constants.DARK_THEME} className={classes.stepForm}>

                                <Form.Field >
                                    <HelpLabel
                                        help="Minimum Size of the Fleet"
                                        label="Minimum Fleet Size"
                                    />
                                    <input
                                        type="number"
                                        placeholder='Minimum Fleet Size'
                                        onChange={this.props.handleChange('minFleetSize')}
                                        defaultValue={values.minFleetSize}
                                    />
                                </Form.Field>
                                <Form.Field >
                                    <HelpLabel
                                        help="Maximum Size of the Fleet"
                                        label="Maximum Fleet Size"
                                    />
                                    <input
                                        type="number"
                                        placeholder='Maximum Fleet Size'
                                        onChange={this.props.handleChange('maxFleetSize')}
                                        defaultValue={values.maxFleetSize}
                                    />
                                </Form.Field>
                                {/* <Form.Field >
                                    <HelpLabel
                                        help="Size of the Instance"
                                        label="Instance Size"
                                    />
                                    <input
                                        placeholder='Instance Size'
                                        onChange={this.props.handleChange('instanceSize')}
                                        defaultValue={values.instanceSize}
                                    />
                                </Form.Field> */}
                                <Form.Dropdown name="instanceSize" placeholder='Select Instance...'
                                    defaultValue={values.instanceSize}
                                    onChange={(e, { value }) => {
                                        e.type = "change"
                                        e.target.value = value
                                        this.props.handleChange('instanceSize')(e)
                                    }
                                    }
                                    fluid  selection options={instance_size_options} />

                                <div className="ui two buttons">
                                    <Button onClick={this.back}>Back</Button>
                                    <Button onClick={this.saveAndContinue} primary>Save And Continue </Button>
                                </div>

                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        )
    }

}

export default ApplicationDeploymentConfig