import {
  Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import _ from 'lodash'
import { ActionCreators } from '../../../../actions'
import classes from './header.module.css'
import {
  Dropdown
} from 'semantic-ui-react'
import SearchBar from './searchbar'
import UserEditModal from '../Users/usereditModal'
import constants from '../../../../constants'
import history from '../../../../lib/history'

class AppHeader extends Component {


  editProfile =(name) => {
    this.props.update_user_attributes({name})
  }

  render() {
    let username = _.get(
      this.props.user, 'username', 'User'
    )
    let attributes = _.get(
      this.props.user, 'attributes', {}
    )
    let user = {
      "userid": attributes.sub,
      "email": attributes.email,
      "name":  attributes.name ,
      "username" : username,
      
    }
    console.log("Checking User atrribs: ", user)
    const user_display_name = _.get(attributes, "name", username)
    let { statusInfo, userInfo} = this.props
    let requestFetchStatus = _.get(
            statusInfo, `${constants.STATUS.GET_USER_PROFILE_STATUS}`, constants.STATUS.UNDEFINED
    )
    if (requestFetchStatus === constants.STATUS.UNDEFINED) {
      this.props.getOrganization(username)
    }
    user['organization'] = "ESW"
    return (
      <div className={`${classes.root} header`}>
        
        <div className={classes.logoParent} onClick={() => history.push(constants.PAGES.LANDING_PAGE)}>
          <div className={classes.logoParent2}>
            <span className={classes.blueLogoText}> CN </span>
            <span className={classes.whiteLogoText}> ExeStream </span>
          </div>
        </div>
        <div style={{ flex: 1 }}> </div>

        <div className={classes.row}>
          <SearchBar type="APP" placeholder="Search App ..." />
          <Dropdown
            pointing='top right'
            className='button icon'
            floating
            labeled
            fluid
            icon="user"
            text={`Hi ${user_display_name} !`}
          >
            <Dropdown.Menu>
              <Dropdown.Header content={user.organization} />
              <Dropdown.Item><UserEditModal on_confirm={this.editProfile} edit={true} user={user} profile={true}/></Dropdown.Item>
              <Dropdown.Item onClick={this.props.signout}>Sign Out</Dropdown.Item>
              
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

    )
  }

}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
  return {
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppHeader)
