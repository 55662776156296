import * as utils from '../lib/utils'
import * as types from '../actions/types'
import createReducer from './createReducer'

let DEFAULT_STATE = {
  'applicationList': [],
  'searchList': [],

}

let DEFAULT_APP_STATE = {
  'app': {}
}

let DEFAULT_SEARCH_STATE = {
  'searchList': []
}

let DEFAULT_QUICKSIGHT_STATE = {
  'embed_url' : ""
}

export const applicationInfo = createReducer(utils.copyDict(DEFAULT_STATE), {
  [types.SET_ALL_APPLICATIONS]: (state, action) => {
    state = { ...state }
    state.applicationList = action.appList
    return state
  },
  [types.SET_CATEGORY_APPLICATIONS]: (state, action) => {
    state = { ...state }
    state[`${action.category}_list`] = action.appList
    return state
  },
  [types.SET_STREAMING_URL]: (state, action) => {
    state = { ...state }
    state[`${action.applicationId}`] = action.streamingUrl
    return state
  },
  [types.RESET]: (state, action) => {
    return utils.copyDict(DEFAULT_STATE)
  }
})

export const appInfo = createReducer(utils.copyDict(DEFAULT_APP_STATE), {
  [types.SET_APP_INFO]: (state, action) => {
    state = { ...state }
    state.app = action.application
    return state
  },
})

export const searchInfo = createReducer(utils.copyDict(DEFAULT_SEARCH_STATE), {
  [types.SET_SEARCH_RESULTS]: (state, action) => {
    state = { ...state }
    state.searchList = action.appList
    return state
  }
})

export const quicksightInfo = createReducer(utils.copyDict(DEFAULT_QUICKSIGHT_STATE), {
  [types.SET_QUICKSIGHT_URL]: (state, action) => {
    state = { ...state }
    state.embedUrl = action.embedUrl
    return state
  }
})