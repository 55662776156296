import {
    Component
} from 'react'
import classes from './formview.module.css'
import { BsPlus } from 'react-icons/bs'
import * as utils from '../../lib/utils'


export default class FormView extends Component {
    constructor(props) {
        super(props)

        this.state = {
            currentData: {},
            input: {},
        }
    }

    getTextField(field) {
        let currentData = this.props.currentData

        return (
            <input
                type={field.type === 'Password' ? 'password' : 'text'}
                className={classes.input}
                placeholder={field.placeholder || `Enter ${field.caption || field.name}`}
                value={currentData[field.name] || field.value || ''}
                disabled={field.disabled}
                onChange={e => {
                    let val = e.target.value

                    currentData[field.name] = val
                    this.props.onChange(currentData)
                }}
                onKeyDown={e => {
                    if (e.keyCode === 13 && this.props.fields.length === 1) {
                        this.onAdd()
                    }
                }}
            />
        )
    }

    render() {
        let { editDisabled,  hideAddButton } = this.props
        return (
            <div className={classes.root} style={this.props.style}>
                {
                    !editDisabled &&
                    <div className={classes.row} style={{
                        ...(this.props.rowStyle ? this.props.rowStyle : {})
                    }}>
                        <div className={classes.itemParent}>
                            {
                                this.props.fields.map(field => (
                                    <div className={classes.fieldItem} key={JSON.stringify(field)} style={{
                                        flex: field.flex || '0 0 33.333333%',
                                    }}>
                                        {
                                            !field.hideKey &&
                                            <span className={classes.fieldKey}>
                                                {
                                                    utils.processPropertyKey((field.caption || field.name).toLocaleUpperCase())
                                                }
                                            </span>
                                        }
                                        {
                                            field.editComponent ? (
                                                field.editComponent(this.props.currentData[field.name], (changedItem) => {

                                                    this.props.currentData[field.name] = changedItem
                                                    this.props.onChange(this.props.currentData)
                                                }, this.props.currentData, newCurrentData => {
                                                    this.props.onChange(newCurrentData)
                                                })
                                            ) : (
                                                field.type === 'String' && field.getEnum ? (
                                                    this.getDropdownField(field)
                                                ) : (
                                                    field.type === 'List' ? (
                                                        this.getListField(field)
                                                    ) : (
                                                        field.type === 'Boolean' ? (
                                                            this.getBooleanField(field)
                                                        ) : (
                                                            this.getTextField(field)
                                                        )
                                                    )
                                                )
                                            )
                                        }
                                    </div>
                                ))
                            }
                        </div>

                        {
                            !hideAddButton &&
                            <div className={classes.buttonParent}>
                                <div style={{ flex: 1 }} />
                                <span className={classes.greyButton} onClick={() => {
                                    this.onAdd()
                                }}>
                                    <BsPlus size={20} className={classes.plusIcon} />
                    Add
                  </span>
                            </div>
                        }

                    </div>
                }
            </div>
        )
    }
}