import history from '../lib/history'
import * as utils from '../lib/utils'
import constants from '../constants'
import * as types from './types'
import { setStatus } from './statusActions'
import { Auth } from 'aws-amplify'
import appsyncHelper from '../lib/appsyncHelper'
import { ToastsStore } from 'react-toasts'


export function checkLogin(callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
  console.log(err)
  ToastsStore.error(err)
})) {
  return async (dispatch, getState) => {
    utils.storageGet('loginInfo', function (error, data) {
      if (!error) {
        let localState = data
        if (localState) {
          if (localState.username &&
            localState.loggedIn === true) {


            Auth.currentAuthenticatedUser().then(user => {
              user = utils.copyDict(user)
              let selectedTab = history.location.pathname
              if (!constants.PAGES.LOGIN_NO_REDIRECT_PAGES.includes(selectedTab))
                history.push(`${constants.PAGES.LANDING_PAGE}`)
              dispatch(setLoginInfo(localState.username, user))
              dispatch(setStatus(constants.STATUS.LOGIN_STATUS, constants.STATUS.SUCCESS))
              callbackOnSuccess()
            })
              .catch(err => {
                console.log("err setupAuthenticationFromLocalStorage", err)
                dispatch(setStatus(constants.STATUS.LOGIN_STATUS, constants.STATUS.FAILURE))
                callbackOnError(err)
              })
          } else {

            history.push('/login')
            callbackOnError("localstate username not found.")
          }
        } else {
          history.push('/login')
          callbackOnError("localstate is missing")
        }
      } else {
        history.push('/login')
        callbackOnError("error while fetching localstate")
      }
    })
  }
}

export function login(username, password, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
  console.log(err)
  ToastsStore.error(err)
})) {
  return async (dispatch, getState) => {
    dispatch(setStatus(constants.STATUS.LOGIN_STATUS, constants.STATUS.IN_PROGRESS))

    if (!username || !password || username.length === 0 || password.length === 0) {
      dispatch(setStatus(constants.STATUS.LOGIN_STATUS, constants.STATUS.FAILURE))
      callbackOnError('Username or Password cannot be empty.')
      return
    }

    return Auth.signIn(username, password).then(user => {
      dispatch(setStatus(constants.STATUS.LOGIN_STATUS, constants.STATUS.SUCCESS))
      if (user.challengeName === 'SMS_MFA' ||
        user.challengeName === 'SOFTWARE_TOKEN_MFA' ||
        user.challengeName === 'NEW_PASSWORD_REQUIRED' ||
        user.challengeName === 'MFA_SETUP') {
        callbackOnError('Couldn\'t login user directy. ' + user.challengeName)
      } else {
        user = utils.copyDict(user)
        dispatch(setLoginInfo(username, user))
        appsyncHelper.configure()
        history.push(`${constants.PAGES.LANDING_PAGE}`)
        callbackOnSuccess()
      }
    }).catch(err => {
      dispatch(setStatus(constants.STATUS.LOGIN_STATUS, constants.STATUS.FAILURE))
      callbackOnError(err.message)
    })


  }
}

export function signup(userdata, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
  console.log(err)
  ToastsStore.error(err)
})) {
  return async (dispatch, getState) => {
    dispatch(setStatus(constants.STATUS.SIGNUP_STATUS, constants.STATUS.IN_PROGRESS))

    if (!userdata.username || !userdata.password || userdata.username.length === 0 || userdata.password.length === 0) {
      dispatch(setStatus(constants.STATUS.SIGNUP_STATUS, constants.STATUS.FAILURE))
      callbackOnError('Username or Password cannot be empty.')
      return
    }
    if (userdata.password !== userdata.confirm_password) {
      dispatch(setStatus(constants.STATUS.SIGNUP_STATUS, constants.STATUS.FAILURE))
      callbackOnError('Password does not match.')
      return
    }
    let user_attributes = {
      // optional - E.164 number convention
      "username": userdata.username,
      "password": userdata.password,
      "attributes": {
        "email": userdata.email,          // optional
        "phone_number": userdata.phone_number,
      }
      // other custom attributes 
    }
    return Auth.signUp(user_attributes).then(user => {
      dispatch(setStatus(constants.STATUS.SIGNUP_STATUS, constants.STATUS.SUCCESS))
      history.push(`${constants.PAGES.LOGIN_PAGE}`)
      ToastsStore.info('Signup Succeeded.')
    }).catch(err => {
      dispatch(setStatus(constants.STATUS.SIGNUP_STATUS, constants.STATUS.FAILURE))
      callbackOnError(err.message)
    })


  }
}

export function update_user_attributes(userdata, callbackOnSuccess = (() => { }), callbackOnError = ((err) => {
  console.log(err)
  ToastsStore.error(err)
})) {
  return async (dispatch, getState) => {
    ToastsStore.warning('Updating Profile.')
    if (!userdata.name ) {
      callbackOnError('Name can not be empty')
      return
    }
    Auth.currentAuthenticatedUser().then(user => {
      return Auth.updateUserAttributes(user, {
        'name': userdata.name
      })
    })
    .then(user =>{
      ToastsStore.success('User updated.')
    }).catch(err => {
      callbackOnError(err.message)
    })
  }
}



export function signout() {
  return async (dispatch, getState) => {
    Auth.signOut().then(() => {

      localStorage.clear()
      utils.storageRemove('loginInfo', function (error) {
        if (error) throw error;
        dispatch({ type: types.RESET })
        history.push('/login')
      });

    })

  }
}


export function setLoginInfo(username, user) {
  return {
    type: types.SET_LOGIN_INFO,
    username,
    user
  }
}