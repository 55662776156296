import React from 'react';
import classes from './login.module.css'
import {
  useLocation
} from "react-router-dom";
import constants from '../../constants'
import _ from 'lodash'

function useQuery() {
  return new URLSearchParams(window.location.search);
}

export default function CustomLoading () {
  let query = useQuery();

  const error_description = query.get("error_description")
  if(error_description){
    if ( error_description.startsWith("Already found an entry for username")){
      const LOGIN_DEVCONNECT_URL = `${constants.AUTH_DOMAIN}/oauth2/authorize?identity_provider=Devconnect&response_type=CODE&client_id=${constants.COGNITO.APP_CLIENT_ID}&scope=email+openid+profile+aws.cognito.signin.user.admin`
      window.location.href = `${LOGIN_DEVCONNECT_URL}&redirect_uri=${window.location.origin}/dashboard`;
    }
  }
  

  return  (<div className={classes.host}>
          <div className={classes.sectionHeader}>
              <h3 className={classes.sectionTitle}>
                  Exestream
              </h3>
              <h3 className={classes.sectionSubTitle}>
                  MarketPlace for Desktop Applications
              </h3>
          </div>

          <div>
             Authenticating User
          </div>
    </div>
  )
}
