import {
  Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import constants from '../../../../constants'
import { ActionCreators } from '../../../../actions'

import {
  Search,
  Label,
  Card,
  Image
} from 'semantic-ui-react'
import _ from 'lodash'
import classes from './header.module.css'
import history from '../../../../lib/history'

const appRenderer = (application) => <Card raised  key={application.id}>

  <Card.Content >
    <Image
      floated='left'
      size='mini'
      src={application.brandLogo}
    />
    <Card.Header >{application.displayName}</Card.Header>
    <Card.Meta >{application.shortDescription}</Card.Meta>
  </Card.Content>
</Card>

const defaultRenderer = ({ name }) => <Label content={name} />

class SearchBar extends Component {

  constructor(props) {
    super(props)
    this.state = {
      value: ""
    }
  }

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.Name })
    history.push(`/application/${result.Id}`)
  }

  searchDefault(value) {

  }

  handleSearchChange = (e, { value }) => {
    this.setState({ value })
    if(value.length < 3)
      return
    if (this.props.type === "APP")
      this.props.searchApps(value)
    else
      this.searchDefault(value)
  }

  transformResults = (results) => {
    return results.map(result=> (
       {
        "title" : result.displayName,
        "sdescription" : result.shortDescription,
        "image" : result.brandLogo,
        "Id": result.id,
        "Name": result.name
      }
    ))
  }

  render() {
    let { statusInfo, searchInfo } = this.props
    let results = []

    if (this.props.type === "APP") {
      results = _.get(searchInfo, "searchList", [])
    }
    
    const searchLoadStatus = _.get(statusInfo, constants.STATUS.SEARCH_RESULT_STATUS, constants.STATUS.UNDEFINED)
    const searchLoading = searchLoadStatus === constants.STATUS.IN_PROGRESS
    const value = _.get(this.state, "value", "")
    results = this.transformResults(results)

    return <Search className={classes.searchBar}
      fluid
      
      loading={searchLoading}
      onResultSelect={this.handleResultSelect}
      onSearchChange={_.debounce(this.handleSearchChange, 500, {
        leading: false,
      })}
      results={results}
      
      defaultValue={value}
      placeholder={this.props.placeholder}

    />
  }


}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
  return {
    searchInfo: state.searchInfo,
    statusInfo: state.statusInfo
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SearchBar)
