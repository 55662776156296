import { combineReducers } from 'redux'

import * as loginReducers from './loginReducers'
import * as statusReducers from './statusReducers'
import * as applicationReducers from './applicationReducers'
import * as userReducers from './userReducers'

export default combineReducers(Object.assign({},
    loginReducers,
    applicationReducers,
    statusReducers,
    userReducers
  ))