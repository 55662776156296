import {
    Component
} from 'react'
import {
    Button,
    Header,
    Grid,
    Container,
    Form
} from 'semantic-ui-react'
import classes from './createApplication.module.css'
import constants from '../../../../constants'

class ApplicationPreview extends Component {

    saveAndContinue = (e) => {
        e.preventDefault()
        this.props.submit()
    }

    back = (e) => {
        e.preventDefault();
        this.props.prevStep();
    }

    render() {
        const { values } = this.props;
        console.log(values.logs)
        return (
            <Container fluid>
                <Grid inverted={constants.DARK_THEME} textAlign="center" centered padded >
                    <Grid.Row columns={1}>
                        <Grid.Column>
                            <Header as='h2' inverted={constants.DARK_THEME} textAlign="center">Application Preview</Header>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        <Grid.Column className={classes.stepFormContainer}>
                            <Form className={classes.stepForm}>
                                <p>Name: {values.name}</p>
                                <p>Display Name: {values.displayName}</p>

                                <div className="ui two buttons">
                                    <Button onClick={this.back}>Back</Button>
                                    <Button onClick={this.saveAndContinue} primary>Submit </Button>
                                </div>
                            </Form>

                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1}>
                        {
                            values.logs.map((val, idx) =>(
                                <p key={idx}>{val}</p>
                            ))
                        }
                    </Grid.Row>
                </Grid>
            </Container>

        )
    }

}

export default ApplicationPreview