import {
    Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../actions'
import {
    Dimmer,
    Loader,
    Icon
} from 'semantic-ui-react'
import constants from '../../../../constants'
import classes from './dashboard.module.css'
import _ from 'lodash'
import { categories } from '../../../../lib/appData'
import ListApplication from '../Application/listApplication'

class CategoryView extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentData: {}
        }
    }

    getCategoryData(categoryId) {
        for (let category of categories) {
            if (category.Id === categoryId) {
                return category
            }
        }
    }
    render() {
        const params = _.get(this.props, "match.params", {})
        let { id } = params;
        let { statusInfo, applicationInfo, fetchStreamingUrl, updatePurchaseStatus } = this.props
        const category = this.getCategoryData(id)
        if (!category) {
            return (
                <div className={classes.root}>
                    <span className={classes.header} style={ this.props.isAdmin ? {}: {
                position: "relative",
                left: "var(--leftbar-width)"
            }}>
                        Category Not found.
                    </span>
                </div>
            )
        }
        let requestFetchStatus = _.get(
            statusInfo, `${constants.STATUS.CATEGORY_APPLICATIONS_FETCH_STATUS}`, constants.STATUS.UNDEFINED
        )
        let application_status = _.get(applicationInfo, `${category.Id}_list`, constants.STATUS.UNDEFINED)

        if (application_status === constants.STATUS.UNDEFINED) {

            this.props.fetchCategoryApplications(category.Id)
        }
        let streaming_url_load_status = _.get(
            statusInfo, `${constants.STATUS.STREAMING_URL_FETCH_STATUS}`, constants.STATUS.UNDEFINED
        )
        let applications = _.get(applicationInfo, `${category.Id}_list`, [])
        return (

            <div className={classes.root}>
                <Dimmer active={requestFetchStatus === constants.STATUS.IN_PROGRESS}>
                    <Loader> Fetching Applications </Loader>
                </Dimmer>
                <span className={classes.header} style={ this.props.isAdmin ? {}: {
                position: "relative",
                left: "var(--leftbar-width)"
            }}>
                    <Icon
                        name={category.icon}
                        color={category.color}
                    />
                    {category.displayName}
                </span>
                <span className={classes.description}>
                    {/* All Applications */}
                </span>

                <div className={classes.content}>
                    <ListApplication applications={applications}
                        className={classes.applicationList}
                        updatePurchaseStatus={updatePurchaseStatus}
                        streamin_url_status={streaming_url_load_status}
                        fetchStreamingUrl={fetchStreamingUrl}
                    />
                </div>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
    return {
        statusInfo: state.statusInfo,
        applicationInfo: state.applicationInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryView)
