import {
    Component
} from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { ActionCreators } from '../../../../actions'
import {
    Dimmer,
    Loader,
    Header
} from 'semantic-ui-react'
import constants from '../../../../constants'
import classes from './dashboard.module.css'
import _ from 'lodash'
import ListApplication from '../Application/listApplication'
import CategoryList from './categoryList'
import { categories } from '../../../../lib/appData'

class Dashboard extends Component {

    render() {
        let { statusInfo, applicationInfo, fetchStreamingUrl, updatePurchaseStatus } = this.props
        let requestFetchStatus = _.get(
            statusInfo, `${constants.STATUS.ALL_APPLICATIONS_FETCH_STATUS}`, constants.STATUS.UNDEFINED
        )

        if (requestFetchStatus === constants.STATUS.UNDEFINED) {
            this.props.fetchAllApplications()
        }


        let applications = _.get(applicationInfo, 'applicationList', [])
        let streaming_url_load_status = _.get(
            statusInfo, `${constants.STATUS.STREAMING_URL_FETCH_STATUS}`, constants.STATUS.UNDEFINED
        )

        
        
        return (

            <div className={classes.root}>
                <Dimmer active={requestFetchStatus === constants.STATUS.IN_PROGRESS}>
                    <Loader> Fetching Applications </Loader>
                </Dimmer>
                <span className={classes.header} style={ this.props.isAdmin ? {}: {
                position: "relative",
                left: "var(--leftbar-width)"
            }}>
                    Discover Amazing Apps
                </span>
                <span className={classes.description}>
                    {/* All Applications */}
                </span>

                <div className={classes.content}>
                    <CategoryList categories={categories} />
                    {
                      this.props.noorganizaation   &&  <Header inverted={constants.DARK_THEME} as='h1'>User not registered in an organization</Header>
                    
                    }
                    {
                      !this.props.noorganizaation   && <ListApplication applications={applications}
                        className={classes.applicationList}
                        streamin_url_status={streaming_url_load_status}
                        updatePurchaseStatus={updatePurchaseStatus}
                        fetchStreamingUrl={fetchStreamingUrl}
                    />
                    }
                </div>
            </div>
        )
    }

}

function mapDispatchToProps(dispatch) {
    return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
    return {
        statusInfo: state.statusInfo,
        applicationInfo: state.applicationInfo
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

