import {
    Component
} from 'react'
import {
    Modal,
    Button,
} from 'semantic-ui-react'
import { Link } from 'react-router-dom';


import classes from './modalBox.module.css'

const BACKGROUND_COLOR = 'var(--home-background-lighter)'

export default class ModalBox extends Component {

    /**
   * Props
   * 
   * open
   * onClose
   * header ?
   * content
   * notScrolling ?
   * onCancel ?
   * onSubmit
   * submitLabel ?
   * cancelLabel ?
   * submitButtonLoading ?
   * hideCancelButton ?
   * hideSubmitButton ?
   */

    render() {

        return (
            <Modal dimmer={'inverted'} open={this.props.open} onClose={() => {
                if (!this.props.submitButtonLoading) {
                    this.props.onClose()
                }
                
            }}
            className={this.props.className}
            >
                {
                    this.props.header && (
                        <Modal.Header className={classes.header} > { this.props.header} </Modal.Header>
                    )
                }

                <Modal.Content>
                    <div style={{

                        borderRadius: 0,
                        backgroundColor: BACKGROUND_COLOR,
                        maxHeight: window.innerHeight - 110,
                        overflow: this.props.notScrolling ? 'visible' : 'auto',

                    }}
                        className={classes.modalContent}
                    >
                        {
                            this.props.content
                        }
                    </div>

                    {
                        (!this.props.hideCancelButton || !this.props.hideSubmitButton) && (
                            <div className={classes.buttonParent}>
                                {
                                    !this.props.hideCancelButton && (
                                        <Button disabled={this.props.submitButtonLoading} className={classes.cancelButton} onClick={() => {
                                            if (this.props.onCancel) {
                                                this.props.onCancel()
                                            } else {
                                                this.props.onClose()
                                            }
                                        }} > { this.props.cancelLabel || 'Cancel'} </Button>
                                    )
                                }
                                {
                                    !this.props.hideSubmitButton && (
                                        <Button primary loading={this.props.submitButtonLoading} className={classes.modalButton} onClick={() => {
                                            this.props.onSubmit()
                                        }}> { this.props.submitLabel || 'Submit'} </Button>
                                    )
                                }
                                {
                                    this.props.showHelpLink && (
                                        <Link to={this.props.HelpLink} className={classes.modalHelpLink}>{this.props.HelpText}</Link>
                                    )
                                }
                            </div>
                        )
                    }
                </Modal.Content>
            </Modal>
        )
    }

}
