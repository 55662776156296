import { Auth }  from 'aws-amplify'
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync'
import gql from 'graphql-tag'
import constants from '../constants'
class AppsyncHelper {

  constructor() {
    this.appsyncClient = null;
  }

  configure() {
    this.appsyncClient = new AWSAppSyncClient({
        url: constants.APPSYNC_URL,
        region: 'us-east-1',
        auth: {
          type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
          jwtToken: async () => (await Auth.currentSession()).getIdToken().getJwtToken(),
        },
        disableOffline: true
      });
  }

   query(query, variables) {
     console.log(this.appsyncClient)
     if (!this.appsyncClient){
       this.configure()
     }
    return this.appsyncClient.query({
        variables,
        fetchPolicy: 'network-only',
        query: gql(query),
      }).then((res) => {
        return res.data;
      })
    
  }

   mutate(mutation , variables) {
    if (!this.appsyncClient){
      this.configure()
    }
    return  this.appsyncClient.mutate({
        variables,
        fetchPolicy: 'no-cache',
        mutation: gql(mutation),
     }).then(res => {
       return res.data;
     })

  }
}

const appsyncHelper = new AppsyncHelper()

export default appsyncHelper
