const constants = {
    STATUS: {
        LOGIN_STATUS: 'LOGIN_STATUS',
        SIGNUP_STATUS: 'SIGNUP_STATUS',
        UNDEFINED: 'UNDEFINED',
        IN_PROGRESS: 'IN_PROGRESS',
        SUCCESS: 'SUCCESS',
        FAILURE: 'FAILURE',
        ALL_APPLICATIONS_FETCH_STATUS: 'all-applications-fetch-status',
        STREAMING_URL_FETCH_STATUS: 'streaming-url-fetch-status',
        SEARCH_RESULT_STATUS: 'search-result-status',
        URL_STATUS : 'url-status',
        APPLICATION_DATA_FETCH_STATUS: 'application-data-fetch-status',
        ALL_USERS_FETCH_STATUS : 'all-users-fetch-status',
        CATEGORY_APPLICATIONS_FETCH_STATUS: 'category-fetch-status',
        GET_USER_PROFILE_STATUS : 'get-user-profile-status',
        GET_QUICKSIGHT_DASHBOARD_URL_STATUS : 'get-quicksight-dashboard-status'
    },
    COGNITO : {
        USER_POOL_ID: 'us-east-1_aNIaIVl2J',
        APP_CLIENT_ID: '646oe42klh8gnv3qj8c9gconhv'
    },
    PAGES : {
        LANDING_PAGE : '/dashboard',
        LOGIN_PAGE: '/login',
        SIGNUP_PAGE: '/signup',
        LIST_APPLICATIONS: '/dashboard',
        CREATE_APPLICATION: '/create-application',
        USERS: '/users',
        CATEGORIES: '/categories',
        LOGIN_NO_REDIRECT_PAGES : ['/dashboard', '/create-application'],
        MONITORING_PAGE: '/monitoring',
        CATEGORY_VIEW: '/categories/:id',
        APPLICATION_VIEW: '/application/:id'
    },
    // APPSYNC_URL: "https://5c5ijdrfufhorcq5ik4ka4huvq.appsync-api.us-east-1.amazonaws.com/graphql",
    APPSYNC_URL : "https://h6nayhdrmffolpqs67yh2k5jau.appsync-api.us-east-1.amazonaws.com/graphql",
    AUTH_DOMAIN: "https://auth.exestream.devfactory.com",
    DARK_THEME: true,
    LOGIN_DEVCONNECT_URL: "",
    SAMPLE_DASHBOARD_URL : 'https://us-west-2.quicksight.aws.amazon.com/embed/7b1c5488ea2c485ba7963bb7fe13260a/dashboards/4a862803-cdb0-4ee6-9903-285ff701538d?code=AYABeG28f-WP7NJWCbsp5xZOtDoAAAABAAdhd3Mta21zAEthcm46YXdzOmttczp1cy13ZXN0LTI6OTAwNjQ5NDI3MTk2OmtleS9iYzAzMTYzMy0xYzJiLTRlMzEtYWM5ZC0yODQ2NDkwZjEyM2YAuAECAQB4a5Vy65_NRyq3IDuV8LaXpVn7UOrMGtsATSOtV23nDYwBMhhDnM4JRBtJv8RAuJ19iwAAAH4wfAYJKoZIhvcNAQcGoG8wbQIBADBoBgkqhkiG9w0BBwEwHgYJYIZIAWUDBAEuMBEEDNVdhVcQr_Fus8LAOAIBEIA7sVozpwnbmcC1I7sHHH4F4Y-PbzJBa9UK3-iJCGQHEfnuR9LIhVJLtqMDrnaYJpA3ZH9oXNE2yI5oBDYCAAAAAAwAABAAAAAAAAAAAAAAAAAAM_oHjKA-AL7oXV_lh9LT3_____8AAAABAAAAAAAAAAAAAAABAAAA5eguOnXw2040i9U728AlBJUIpU_Fr59xqLGRss-fs82QoatjzRalQtX5QeEecMMJ_Tps_5IKltwdbeoZsmKT0RLfk1rct998Kb-ST4E3jGWD9qn_4fjdPfwSpvs1IsrWq3DtqwMRNMQHBVtTa5Cu4xnsswkM9IsagEsj6grK67D_xLBawqeS9yRgFpbDYoOBW82fOxm-abz0TwGa0aLFtGGJXvlTPPXmZAgy38Y_LRC7MSkIyNIu0IlWwrJYq6E1jAohDHrdurrsREtQfIRQyFA9GV3wn-eIsVr4BeMikI9cyGRk1-0eFEPBjMGOlxDpByVMQaJj&identityprovider=quicksight&isauthcode=true'
}
export default constants
