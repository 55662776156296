import React from 'react';
import ReactDOM from 'react-dom';
import { Component } from 'react'
import { connect } from 'react-redux'
import { Provider } from 'react-redux'
import { bindActionCreators } from 'redux'
import { Router, withRouter } from "react-router-dom"
import { ToastsContainer, ToastsStore } from 'react-toasts'
import Amplify, { Auth } from 'aws-amplify';
import { AmplifyAuthenticator} from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange,  UI_AUTH_CHANNEL,
  AUTH_STATE_CHANGE_EVENT } from '@aws-amplify/ui-components';
import {CustomSignIn, CustomLoading} from './screens/Login'
import 'semantic-ui-css/semantic.min.css'
import App from './screens/App'
import history from './lib/history'
import { store } from './store'
import { ActionCreators } from './actions'
import constants from './constants'
import reportWebVitals from './reportWebVitals';
import { Hub } from "@aws-amplify/core";

// import './index1.css';
(async () => {
  if ( constants.DARK_THEME === false) {
    const module = await import(
      "./index1.css"
    );
  }else{
    const module = await import(
      "./index.css"
    );
  }
})();
 

window.LOG_LEVEL = 'INFO'
typeof window !== "undefined" && (window.React = React) // This is required for React Chrome extension during developemnt

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    userPoolId: constants.COGNITO.USER_POOL_ID,
    userPoolWebClientId: constants.COGNITO.APP_CLIENT_ID,
    oauth: {
      domain: 'auth.exestream.devfactory.com',
      scope: ['email', 'openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${window.location.origin}/dashboard`,
      redirectSignOut: `${window.location.origin}/`,
      clientId: constants.COGNITO.APP_CLIENT_ID,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    }
  }
})
const currentConfig = Auth.configure();



const AuthStateApp = () => {
  const [authState, setAuthState] = React.useState();
  const [user, setUser] = React.useState();
 
  React.useEffect(() => {
    return onAuthUIStateChange((nextAuthState, authData) => {
      setAuthState(nextAuthState);
      setUser(authData)
      if (nextAuthState === AuthState.VerifyContact) {
        Hub.dispatch(UI_AUTH_CHANNEL, {
          event: AUTH_STATE_CHANGE_EVENT,
          message: AuthState.SignedIn,
        });
      }
      
      
    });
  }, []);
  return ((authState === AuthState.SignedIn && user) || (authState === AuthState.VerifyContact)) ? (
    <App user={user} />
  ) : (
    
    <AmplifyAuthenticator >
      <div slot= "sign-in">
      <CustomSignIn/>
      </div>
      <div slot= "loading">
      <CustomLoading/>
      </div>
    </AmplifyAuthenticator>
  );
}


class Root extends Component {

  componentDidMount() {
    // this.props.checkLogin()
  }

  render() {
   
    const AppWithRouter = withRouter(props => <AuthStateApp {...props} />);

    return (
      <Router history={history}>
        <ToastsContainer store={ToastsStore} />
        <AppWithRouter />
      </Router>
    )
  }

}

function mapDispatchToProps(dispatch) {
  return { ...bindActionCreators(ActionCreators, dispatch), dispatch }
}

function mapStateToProps(state) {
  return {
  }
}

const RootComponent = connect(mapStateToProps, mapDispatchToProps)(Root)
const app = (
  <Provider store={store}>
    <RootComponent />
  </Provider>
)

ReactDOM.render(app, document.getElementById('root'))

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
